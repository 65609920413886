import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import {
  Tooltip,
  Box,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  DialogContent
} from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import { RouteComponentProps } from 'react-router';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable, {
  SelectableRows,
  MUIDataTableMeta,
  MUIDataTableColumnOptions
} from 'mui-datatables';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import { ReferentialCode } from '../../../helpers/Constants';
import Referentials from '../../../helpers/Referentials.json';
import { IReferential } from '../../../interfaces/IReferential';
import moment from 'moment';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {  CountrySupport } from '../../../interfaces/Organization';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import NumberFormat from 'react-number-format';

interface ICountrySupportComponentState {
  newCountrySupport: CountrySupport;
  countrySupports: CountrySupport[];
  isLoading: boolean;
  isDialogOpen: boolean;
  newCountrySupportId: number | null;
  countryList: IReferential[];
}
type ICountrySupportComponentProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string; workflowId: string }>;
class CountrySupportComponent extends React.PureComponent<
  ICountrySupportComponentProps,
  ICountrySupportComponentState
> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private appReferentialService!: IReferentialService;
  static contextType = ApplicationContext;
  private newCountrySupport = {
    id: 0,
    countryId: 0,
    country: null,
    email: ''
  } as CountrySupport;

  state = {
    isLoading: false,
    isDialogOpen: false,
    newCountrySupport: this.newCountrySupport,
    countrySupports: [],
    newCountrySupportId: null,
    countryList: [] as IReferential[]
  } as ICountrySupportComponentState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadCountrySupports();
      }
    );
  }

  loadCountrySupports = async () => {
    const countryRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.Country
    );
    const [countrySupports, countryList] = await Promise.all([
      this.organizationService.GetUserCountrySupports(),
      this.appReferentialService.Get(countryRef!.baseUrl)
    ]);

    this.setState({
      countrySupports: countrySupports,
      isLoading: false,
      newCountrySupport: { ...this.newCountrySupport },
      countryList: countryList
    });
  };

  private handleCountryChange = (newValue: IReferential | null) => {
    this.setState({
      newCountrySupport: {
        ...this.state.newCountrySupport,
        country: newValue,
        countryId: newValue !== null ? newValue!.id : 0
      }
    });
  };

  private handleValueChange = (value: string) => {
    this.setState({
      newCountrySupport: {
        ...this.state.newCountrySupport,
        email: value
      }
    });
  };

  public saveCountrySupport = async () => {
    if (this.state.newCountrySupportId === null) {
      if (
        this.state.countrySupports.findIndex(
          (item) => item.countryId === this.state.newCountrySupport.countryId
        ) !== -1
      ) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'COUNTRY_SUPPORT_ALREADY_EXIST',
            'Exista deja un email de suport pentru aceasta tara!'
          ),
          { variant: 'error' }
        );
        return;
      }
      try {
        await this.organizationService.AddCountrySupport(this.state.newCountrySupport);
        await this.loadCountrySupports();
        this.closeDialog();
        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    } else {
      try {
        await this.organizationService.UpdateCountrySupport(this.state.newCountrySupport);
        await this.loadCountrySupports();
        this.closeDialog();
        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };

  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={2}
      />
    );
  };

  public renderAddForm() {
    return (
      <ValidatorForm onSubmit={this.saveCountrySupport} instantValidate={true}>
        <Autocomplete
          id="country"
          className="m-2"
          options={this.state.countryList}
          value={this.state.newCountrySupport.country}
          onChange={(e: any, newValue: IReferential | null) =>
            this.handleCountryChange(newValue)
          }
          getOptionLabel={(option: IReferential) => option.displayName || ''}
          renderInput={(params) => (
            <TextValidator
              {...params}
              name="country"
              value={this.state.newCountrySupport.country || ''}
              label={this.translatorService.Tranlate(
                'EDIT_ORGANIZATION_COUNTRY_LABEL',
                'Tara'
              )}
              fullWidth
              validators={[]}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_REQUIRED',
                  'Campul este obligatoriu'
                )
              ]}
            />
          )}
        />
        <TextValidator
          fullWidth
          name="email"
          className="m-2"
          id="email"
          value={this.state.newCountrySupport.email || ''}
          onChange={(e: any) => this.handleValueChange(e.target.value)}
          label={this.translatorService.Tranlate(
            'COUNTRY_SUPPORT_EMAIL_LABEL',
            'Email'
          )}
          validators={['required']}
          errorMessages={[
            this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
          ]}
        />

        <div className="text-right">
          <Button
            className="m-2"
            variant="outlined"
            color="primary"
            onClick={this.closeDialog}
          >
            {this.translatorService.Tranlate('EDIT_ORGANIZATION_CANCEL_BTN', 'Anuleaza')}
          </Button>
          <Button className="m-2" variant="contained" color="primary" type="submit">
            {this.translatorService.Tranlate('EDIT_ORGANIZATION_SAVE_BTN', 'Salveaza')}
          </Button>
        </div>
    </ValidatorForm>
    );
  }
  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'country',
        label: this.translatorService.Tranlate('ORGANIZATIONS_COUNTRY_HEADER', 'Tara'),
        options: {
          sort: true,
          customSort: (a: IReferential, b: IReferential) => {
            debugger;
            return a.name!.localeCompare(b.name!);
          },
          customBodyRender: (value: IReferential) => {
            return value ? value.displayName : '';
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'email',
        label: this.translatorService.Tranlate(
          'COUNTRY_SUPPORT_EMAIL_LABEL',
          'Email'
        )
      },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate('USERS_DATE_MODIFICATION_HEADER', 'Data Modificare'),
        options: {
          filter: false,
          sortDirection: 'desc',
          customBodyRender: (value: Date) =>
            moment.utc(value).local().format(this.props.appState.longDateFormat)
        } as MUIDataTableColumnOptions
      },
      {
        name: '',
        options: {
          viewColumns: false,
          filter: false,
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <>
                <IconButton
                  aria-label="edit"
                  color="primary"
                  className="text-primary"
                  size="small"
                  onClick={(e) => this.newCountrySupportPopup(tableMeta.rowData[0])}
                >
                    <EditOutlinedIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  color="inherit"
                  className="text-danger"
                  size="small"
                  onClick={(e) => this.deleteCountrySupport(e, tableMeta)}
                >
                  <DeleteOutlineTwoToneIcon />
                </IconButton>
              </>
            );
          }
        }
      }];
    return columns;
  };

  deleteCountrySupport = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const id = tableMeta.rowData[0];
      await this.organizationService.RemoveCountrySupport(id);
      await this.loadCountrySupports();
      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  newCountrySupportPopup = async (countrySupportId: number | null) => {
    let newCountrySupport: CountrySupport;
    if (countrySupportId == null) {
      newCountrySupport = { ...this.state.newCountrySupport } as CountrySupport;
    } else {
      newCountrySupport = this.state.countrySupports.find((item) => item.id === countrySupportId)!;
    }
    this.setState({
      isDialogOpen: true,
      newCountrySupport: newCountrySupport,
      newCountrySupportId: countrySupportId
    });
  };

  closeDialog = async () => {
    this.setState(
        { 
          isDialogOpen: false, 
          newCountrySupport: this.newCountrySupport 
        }
      );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    const options = {
      filter: true,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      sort: false,
      // responsive: "stacked",
      customToolbar: () => {
        return (
          <Tooltip title={this.translatorService.Tranlate('Add', 'Adauga')}>
            <IconButton
              aria-label="add"
              color="primary"
              className="m-2"
              onClick={(e) => this.newCountrySupportPopup(null)}
            >
              <AddCircleTwoToneIcon />
            </IconButton>
          </Tooltip>
        );
      }
    };
    return (
      <Fragment>
    
       <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.countrySupports}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>
        {this.state.isDialogOpen ? (
          <Dialog
            onClose={this.closeDialog}
            aria-labelledby="customized-dialog-title"
            open={this.state.isDialogOpen}
            fullWidth={true}
            maxWidth={'sm'}
          >
            <DialogTitle id="customized-dialog-title">
              <Grid container>
                <Grid item xs={11}>
                  <Typography variant="h4">
                  {!this.state.newCountrySupportId ? 
                    this.translatorService.Tranlate(
                      'COUNTRY_SUPPORT_ADD_MODAL_TITLE',
                      'Adauga support pe tara'
                    ) : this.translatorService.Tranlate(
                      'COUNTRY_SUPPORT_UPDATE_MODAL_TITLE',
                      'Actualizeaza support pe tara'
                    )}               
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton aria-label="close" onClick={this.closeDialog} size={'small'}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent dividers>{this.renderAddForm()}</DialogContent>
          </Dialog>) : null}
      </Fragment>
    );
  }
}
export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(CountrySupportComponent as any));