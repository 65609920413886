import React, { PureComponent } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Typography } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { MavoVehicleOrder } from '../../interfaces/Vehicle';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { AppState } from '../../store';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { AppUser } from '../../interfaces/AppUser';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';
import { IVehicleService } from '../../services/Interfaces/IVehicleService';
import { withSnackbar, ProviderContext } from 'notistack';
import { Autocomplete } from '@material-ui/lab';
import { MavoPersonTypeEnum } from '../../helpers/Constants';
import NumberFormat from 'react-number-format';
import { ScaleLoader } from 'react-spinners';
import _ from 'lodash';
import { IReferential } from '../../interfaces/IReferential';
import { CNP } from 'romanian-personal-identity-code-validator';

class LocalizedUtils extends MomentUtils {
  // dateFormat = "DD MM YYYY";
}

interface Props extends ProviderContext {
  appState: AppState;
  isDialogOpenOrder: boolean;
  closeDialogOrder: () => void;
  orderForm: MavoVehicleOrder;
  handleChangeOrder: (fieldName: string, isNumber: boolean) => (e: any, newValue?: IReferential | null) => void;
  handleUserChange: (user: AppUser | null) => void;
  handleOrderDateChange: (date: MaterialUiPickersDate | null,value?: string | null) => void;
  vehicleId: number | undefined;
  countryList: IReferential[];
  regionList: IReferential[];
  cityList: IReferential[];
  handleCountryChange: (newValue: IReferential | null) => void;
  handleRegionChange: (newValue: IReferential | null) => void;
  handleCityChange: (newValue: IReferential | null) => void;
}

interface State {
  users: AppUser[];
  executing: boolean;
  orderDateError: string;
  isLoading: boolean;
  isLoadingUsers: boolean;
}

class MavoVehicleOrderDialog extends PureComponent<Props, State> {
  private translatorService!: ITranslatorService;
  private appUserService!: IAppUserService;
  private vehicleService!: IVehicleService;

  static contextType = ApplicationContext;

  constructor(props: Props) {
    super(props);
  }

  state = {
    users: [] as AppUser[],
    executing: false,
    orderDateError: '',
    isLoading: false,
    isLoadingUsers: false
  }
  
  public async componentDidMount() {
    ValidatorForm.addValidationRule('isValidCNP', (value) => {
      if (!value) {
        return true;
      }

      const cnp = new CNP(value);

      if (!cnp.isValid()) {
        return false;
      }
      return true;
    });

    this.setState({ isLoadingUsers: true });
    const users = await this.appUserService.GetMavoUserAppUsers();
    this.setState({ users: users.users,  isLoadingUsers: false });
  }
  
  handleSaveOrder = async () => {
    if (this.props.orderForm.orderDate == null) {
      return;
    }

    const request = this.props.orderForm.personTypeId == MavoPersonTypeEnum.PF ? {
        mavoVehicleId: this.props.orderForm.mavoVehicleId,
        personTypeId: this.props.orderForm.personTypeId,
        name: this.props.orderForm.firstName + ' ' + this.props.orderForm.lastName,
        cnp: this.props.orderForm.cnp,
        adress: this.props.orderForm.adress,
        orderDate: this.props.orderForm.orderDate,
        price: this.props.orderForm.price,
        countryId: this.props.orderForm.countryId,
        regionId: this.props.orderForm.regionId,
        cityId: this.props.orderForm.cityId
      } as MavoVehicleOrder : 
      {
        mavoVehicleId: this.props.orderForm.mavoVehicleId,
        personTypeId: this.props.orderForm.personTypeId,
        price: this.props.orderForm.price,
        orderDate: this.props.orderForm.orderDate,
        userId: this.props.orderForm.userId,
        user: this.props.orderForm.user
      } as MavoVehicleOrder;

    try {
      this.setState({ executing: true, isLoading: true });
      const response = await this.vehicleService.AddMavoVehicleOrder(request);
      if (response.orderDate) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ORDER_SUCCESS', 'Comanda a fost salvata cu succes'), { variant: 'success' });
      } else {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ORDER_ERROR', 'Comanda nu a putut fi salvata'), { variant: 'error' });
      }
      this.setState({ executing: false, isLoading: false });
      this.props.closeDialogOrder();
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ORDER_ERROR', 'Comanda nu a putut fi salvata'), { variant: 'error' });
      this.setState({ executing: false, isLoading: false });
    }
  }

  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={0}
      />
    );
  };

  
  validateDates = (orderDate: string | null): void => {
    /*
    Validate end date
    */
    let orderDateError = '';
    if (!orderDate) {
      orderDateError = this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu');
    }

    this.setState({
      orderDateError: orderDateError
    });

  };

  NumberDecimalFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={2}
      />
    );
  };

  private getRegionsOptions(): IReferential[] {
    const filteredRegions = this.props.regionList.filter(
      (region) => (this.props.orderForm.countryId && region.dependencyId) && region.dependencyId === this.props.orderForm.countryId
    );

    return filteredRegions || [];
  }

  private getCitiesOptions(): IReferential[] {
    const filteredCities = this.props.cityList.filter(
      (city) => (this.props.orderForm.regionId && city.dependencyId) && city.dependencyId === this.props.orderForm.regionId
    );

    return filteredCities || [];
  }

  public renderOrderForm = () => {
    return (
      <div className="text-center">
        <Grid container spacing={0} key={this.props.orderForm.personTypeId}>
          <Grid item xs={12}>
            <TextValidator
              fullWidth
              className="m-2 pr-3"
              id="price"
              name="price"
              value={this.props.orderForm.price || ''}
              onChange={(e: any) =>
                this.props.handleChangeOrder('price', true)(e)
              }
              placeholder={this.translatorService.Tranlate(
                'CASE_OTHER_SERVICE_FORM_SELLING_OFFER_DIALOG',
                'Pret vanzare'
              )}
              validators={['required', 'minNumber:1']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_REQUIRED',
                  'Campul este obligatoriu'
                ),
                this.translatorService.Tranlate('PRICE_INVALID', 'Pretul nu este valid')
              ]}
              label={this.translatorService.Tranlate(
                'CASE_OTHER_SERVICE_FORM_SELLING_OFFER_DIALOG',
                'Pret vanzare'
              )}
              InputProps={{
                inputComponent: this.NumberDecimalFormatCustom
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <RadioGroup
            row
            aria-labelledby="controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={this.props.orderForm.personTypeId  || ''}
            onChange={(e: any) =>
              this.props.handleChangeOrder('personTypeId', true)(e)
            }
            className="m-2 pr-3"
          >
            <FormControlLabel
              control={<Radio />}
              value={MavoPersonTypeEnum.PF}
              label={this.translatorService.Tranlate('PERSON_TYPE_NATURAL', 'Persoana fizica')}
            />
            <FormControlLabel
              control={<Radio />}
              value={MavoPersonTypeEnum.PJ}
              label={this.translatorService.Tranlate('PERSON_TYPE_LEGAL', 'Persoana juridica')}
            />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <MuiPickersUtilsProvider
            libInstance={moment}
            utils={LocalizedUtils}
            locale={this.props.appState.language}
          >
            <DatePicker
              fullWidth
              className="m-2 pr-3"
              openTo="date"
              views={['year', 'month', 'date']}
              variant="inline"
              format={this.props.appState.dateFormat.toUpperCase()}
              margin="normal"
              id="orderDate"
              label={this.translatorService.Tranlate(
                'DIRECT_ORDER_DATE',
                'Data comenzii'
              )}
              value={this.props.orderForm?.orderDate  || null}
              onChange={(e) => {
                this.props.handleOrderDateChange(e);
                if (e === null || !e.isValid) {
                  return;
                }
                this.setState({
                  orderDateError: ''
                });
              }}
              autoOk={true}
              minDate={new Date()}
              required={true}
              error={this.state.orderDateError ? true : false}
              helperText={
                this.state.orderDateError
              }
            />
          </MuiPickersUtilsProvider>
        </Grid>
        {this.props.orderForm.personTypeId && this.props.orderForm.personTypeId === MavoPersonTypeEnum.PJ ? (
          <Grid item xs={12}>
            <Autocomplete
              id="user"
              className="m-2 cb"
              options={this.state.users.sort(function (a, b) {
                return a.userName.localeCompare(b.userName);
              })}
              value={this.props.orderForm.user ?? null}
              onChange={(e: any, newValue: AppUser | null) => this.props.handleUserChange(newValue)}
              getOptionLabel={(option: AppUser) => (option.userName || '') + ' (' + (option.organization!.name || '') + ')'}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  name="user"
                  value={this.props.orderForm.user ?? null}
                  label={this.translatorService.Tranlate('MENU_NOMENCLATURES_TAB_AGENT', 'Agent')}
                  fullWidth
                  validators={['required']}
                  errorMessages={[
                    this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                  ]}
                />
              )}
            />
          </Grid>
        ) : (
          <>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <TextValidator
                  className="m-2 pr-3"
                  fullWidth
                  id="lastName"
                  name="lastName"
                  type='text'
                  value={this.props.orderForm.lastName  || ''}
                  onChange={(e: any) =>
                    this.props.handleChangeOrder('lastName', false)(e)
                  }                  
                  placeholder={this.translatorService.Tranlate(
                    'EDIT_USER_LAST_NAME_FIELD',
                    'Nume'
                  )}
                  validators={['required']}
                  errorMessages={
                    this.translatorService.Tranlate(
                      'VALIDATORS_REQUIRED',
                      'Campul este obligatoriu'
                    )
                  }
                  label={this.translatorService.Tranlate(
                    'EDIT_USER_LAST_NAME_FIELD',
                    'Nume'
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TextValidator
                  className="m-2 pr-3"
                  fullWidth
                  id="firstName"
                  name="firstName"
                  type='text'
                  value={this.props.orderForm.firstName  || ''}
                  onChange={(e: any) =>
                    this.props.handleChangeOrder('firstName', false)(e)
                  }                      
                  placeholder={this.translatorService.Tranlate(
                    'USERS_FIRST_NAME_HEADER',
                    'Prenume'
                  )}
                  validators={['required']}
                  errorMessages={
                    this.translatorService.Tranlate(
                      'VALIDATORS_REQUIRED',
                      'Campul este obligatoriu'
                    )
                  }
                  label={this.translatorService.Tranlate(
                    'USERS_FIRST_NAME_HEADER',
                    'Prenume'
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Autocomplete
                  id="country"
                  className="m-2"
                  options={this.props.countryList}
                  value={this.props.orderForm.country}
                  onChange={(e: any, newValue: IReferential | null) =>
                    this.props.handleCountryChange(newValue)
                  }
                  getOptionLabel={(option: IReferential) => option.displayName || ''}
                  disabled={true}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      name="country"
                      value={this.props.orderForm.country || ''}
                      label={this.translatorService.Tranlate(
                        'EDIT_ORGANIZATION_COUNTRY_LABEL',
                        'Tara'
                      )}
                      fullWidth
                      validators={['required']}
                      errorMessages={[
                        this.translatorService.Tranlate(
                          'VALIDATORS_REQUIRED',
                          'Campul este obligatoriu'
                        )
                      ]}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  id="region"
                  className="m-2"
                  options={this.getRegionsOptions()}
                  value={this.props.orderForm.region}
                  onChange={(e: any, newValue: IReferential | null) =>
                    this.props.handleRegionChange(newValue)
                  }
                  getOptionLabel={(option: IReferential) => option.displayName || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      name="region"
                      value={this.props.orderForm.region || ''}
                      label={this.translatorService.Tranlate(
                        'EDIT_ORGANIZATION_COUNTY_LABEL',
                        'Judet'
                      )}
                      fullWidth
                      validators={['required']}
                      errorMessages={[
                        this.translatorService.Tranlate(
                          'VALIDATORS_REQUIRED',
                          'Campul este obligatoriu'
                        )
                      ]}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Autocomplete
                  id="city"
                  className="m-2"
                  options={this.getCitiesOptions()}
                  value={this.props.orderForm.city}
                  onChange={(e: any, newValue: IReferential | null) =>
                    this.props.handleCityChange(newValue)
                  }
                  getOptionLabel={(option: IReferential) => option.displayName || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      name="cityInfo"
                      value={this.props.orderForm.city || ''}
                      label={this.translatorService.Tranlate(
                        'EDIT_ORGANIZATION_CITY_1_LABEL',
                        'Oras'
                      )}
                      fullWidth
                      validators={['required']}
                      errorMessages={[
                        this.translatorService.Tranlate(
                          'VALIDATORS_REQUIRED',
                          'Campul este obligatoriu'
                        )
                      ]}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TextValidator
                  className="m-2 pr-3"
                  fullWidth
                  id="address"
                  name="address"
                  type='text'
                  value={this.props.orderForm.adress  || ''}
                  onChange={(e: any) =>
                    this.props.handleChangeOrder('adress', false)(e)
                  }                   
                  placeholder={this.translatorService.Tranlate(
                    'EDIT_ORGANIZATION_ADDRESS_LABEL',
                    'Adresa'
                  )}
                  validators={['required']}
                  errorMessages={
                    this.translatorService.Tranlate(
                      'VALIDATORS_REQUIRED',
                      'Campul este obligatoriu'
                    )
                  }
                  label={this.translatorService.Tranlate(
                    'EDIT_ORGANIZATION_ADDRESS_LABEL',
                    'Adresa'
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <TextValidator
                  className="m-2 pr-3"
                  fullWidth
                  id="cnp"
                  name="cnp"
                  value={this.props.orderForm.cnp  || ''}
                  onChange={(e: any) =>
                    this.props.handleChangeOrder('cnp', false)(e)
                  }                   
                  placeholder={this.translatorService.Tranlate(
                    'FRM_EVENT_DATA_CNP',
                    'CNP'
                  )}
                  validators={['isValidCNP', 'required']}
                  errorMessages={[
                    this.translatorService.Tranlate('CLIENT_DATA_FORM_VALIDATOR_CNP', 'CNP invalid'),
                    this.translatorService.Tranlate(
                      'VALIDATORS_REQUIRED',
                      'Campul este obligatoriu'
                    )
                  ]}
                  label={this.translatorService.Tranlate(
                    'FRM_EVENT_DATA_CNP',
                    'CNP'
                  )}
                />
            </Grid>
          </Grid>
        </>
        )}
      </div>
    );
  };

  renderOrderContent = () => {
    return (
      <ValidatorForm onSubmit={this.handleSaveOrder} instantValidate={true}>
        {this.renderOrderForm()}
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        <div className="text-right mt-4">
          <Button
            className="m-2"
            variant="outlined"
            color="primary"
            onClick={this.props.closeDialogOrder}
          >
            {this.translatorService.Tranlate('MAVO_VEHICLE_IMPORT_CLOSE_BTN', 'Renunta')}
          </Button>
          <Button className="m-2" 
            variant="contained" 
            color="primary" 
            type="submit"
            disabled={this.state.executing}
            onClick={() => {
              this.validateDates(this.props.orderForm!.orderDate);
            }}
            >
            {this.translatorService.Tranlate('SAVE', 'Salveaza')}
          </Button>
        </div>
      </ValidatorForm>
    );
  };

  render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.appUserService = (this.context as AppContext).appUserService;
    this.vehicleService = (this.context as AppContext).vehicleService;

    return (
      <Dialog
        onClose={this.props.closeDialogOrder}
        aria-labelledby="customized-dialog-title"
        open={this.props.isDialogOpenOrder}
        fullScreen={false}
        fullWidth={true}
        >
        <DialogTitle id="customized-dialog-title">
          <Grid container>
            <Grid item xs={11}>
              <Typography className="MuiTypography-h4">
                {this.translatorService.Tranlate(
                  'MAVO_VEHICLES_VEHICLE_PLACE_ORDER',
                  'Lansare comanda'
                )}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton aria-label="close" onClick={this.props.closeDialogOrder} size={'small'}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoadingUsers} />
          </div>
          {!this.state.isLoadingUsers ? this.renderOrderContent() : null}
        </DialogContent>
      </Dialog>
    );
  }
}

export default withSnackbar(MavoVehicleOrderDialog);