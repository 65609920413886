import { Grid, Card, Box } from '@material-ui/core';
import * as React from 'react';
import { ApplicationContext } from '../context/Contexts';
import { useContext } from 'react';
import { connect } from 'react-redux';
import CaseNewInNotification from '../components/cases/CaseNewInNotification';
import CaseMessageNotification from '../components/cases/CaseMessageNotification';
import CaseTask from '../components/cases/CaseTask';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useState } from 'react';
import { Guid } from 'guid-typescript';
import AppointmentNewInNotification from './cases/AppointmentNewInNotification';
import { AppActionCreators, ApplicationState, AppState } from '../store';
import { withRouter } from 'react-router';
import { CountryCodeEnum } from '../helpers/Constants';
import { TranslatorService } from '../services/TranslatorService';

const Home = (props: any) => {
  const context = useContext(ApplicationContext);
  const translatorService = new TranslatorService();

  const [changeUID, setUID] = useState('');
  return (
    <React.Fragment>
      <Box m={1} p={1}>
        {props.appState.appUser?.organization?.country?.code?.toUpperCase() === CountryCodeEnum.CZ.toString() ? (
          <>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <Card className="card-box mb-4">
                  <div style={{ backgroundColor: '#1F2857' }} className="card-header text-white">
                    <div className="card-header--title">
                      <b>{translatorService.Tranlate('APPOINTMENTS_NEW_ENTRIES', 'Programari')}</b>
                    </div>
                  </div>
                  <div className="height-280">
                    <PerfectScrollbar>
                      <AppointmentNewInNotification
                        {...props}
                        taskAdded={() => {
                          setUID(Guid.create().toString());
                        } }
                      ></AppointmentNewInNotification>
                    </PerfectScrollbar>
                  </div>
                </Card>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Card className="card-box mb-4">
                  <div style={{ backgroundColor: '#1F2857' }} className="card-header text-white">
                    <div className="card-header--title">
                      <b>{translatorService.Tranlate('CASE_NEW_ENTRIES', 'Dosare')}</b>
                    </div>
                  </div>
                  <div className="height-280">
                    <PerfectScrollbar>
                      <CaseNewInNotification
                        {...props}
                        taskAdded={() => {
                          setUID(Guid.create().toString());
                        } }
                      ></CaseNewInNotification>
                    </PerfectScrollbar>
                  </div>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <Card className="card-box mb-4">
                  <div style={{ backgroundColor: '#1F2857' }} className="card-header text-white pr-1">
                    <div className="card-header--title">
                      <b>{translatorService.Tranlate('CASE_MESSAGES', 'Mesaje')}</b>
                    </div>
                  </div>
                  <div className="height-280">
                    <PerfectScrollbar>
                      <CaseMessageNotification {...props}></CaseMessageNotification>
                    </PerfectScrollbar>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </>
          ) : (
            <>
              <Grid container spacing={4}>
                <Grid item xs={12} lg={6}>
                  <Card className="card-box mb-4">
                    <div style={{ backgroundColor: '#1F2857' }} className="card-header text-white">
                      <div className="card-header--title">
                        <b>{translatorService.Tranlate('CASE_NEW_ENTRIES', 'Dosare')}</b>
                      </div>
                    </div>
                    <div className="height-280">
                      <PerfectScrollbar>
                        <CaseNewInNotification
                          {...props}
                          taskAdded={() => {
                            setUID(Guid.create().toString());
                          } }
                        ></CaseNewInNotification>
                      </PerfectScrollbar>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Card className="card-box mb-4">
                    <div style={{ backgroundColor: '#1F2857' }} className="card-header text-white">
                      <div className="card-header--title">
                        <b>{translatorService.Tranlate('APPOINTMENTS_NEW_ENTRIES', 'Programari')}</b>
                      </div>
                    </div>
                    <div className="height-280">
                      <PerfectScrollbar>
                        <AppointmentNewInNotification
                          {...props}
                          taskAdded={() => {
                            setUID(Guid.create().toString());
                          } }
                        ></AppointmentNewInNotification>
                      </PerfectScrollbar>
                    </div>
                  </Card>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                  <Grid item xs={12} lg={6}>
                    <Card className="card-box mb-4">
                      <div style={{ backgroundColor: '#1F2857' }} className="card-header text-white pr-1">
                        <div className="card-header--title">
                          <b>{translatorService.Tranlate('CASE_MESSAGES', 'Mesaje')}</b>
                        </div>
                      </div>
                      <div className="height-280">
                        <PerfectScrollbar>
                          <CaseMessageNotification {...props}></CaseMessageNotification>
                        </PerfectScrollbar>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Card className="card-box mb-4">
                      <div style={{ backgroundColor: '#1F2857' }} className="card-header text-white pr-1">
                        <div className="card-header--title">
                          <b>{translatorService.Tranlate('CASE_TASKS', 'Lista sarcini')}</b>
                        </div>
                      </div>
                      <div className="height-280">
                        <PerfectScrollbar>
                          <CaseTask {...props} changeUID={changeUID}></CaseTask>
                        </PerfectScrollbar>
                      </div>
                    </Card>
                  </Grid>
                </Grid>
              </>
          ) }
      </Box>
    </React.Fragment>
  );
};

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: any
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  AppActionCreators,
  mergeProps
)(withRouter(Home as any));