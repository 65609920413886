import * as React from 'react';
import { ApplicationState, AppState } from '../../store';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import {
  Card,
  Grid,
  Tab,
  Tabs,
  AppBar,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  CircularProgress
} from '@material-ui/core';
import TabPanel from '../_shared/Tabs';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import { MUITranslations } from '../../helpers/MUITableTranslations';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  MavoVoInvoiceFilters,
  MavoVoInvoicesList,
  MavoVehicleInvoice,
  MavoVoInvoiceExportFilters,
  MavoVehicleCreateInvoiceRequestDto,
  DownloadResultsRequest,
  CreateEInvoiceRequest
} from '../../interfaces/Vehicle';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Loader from '../Loader';
import noop from 'lodash/noop';
import _ from 'lodash';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MavoVehicleActionCreators } from '../../store/actions/MavoVehicle';
import { MavoVehicleState } from '../../store/reducers/MavoVehicle';
import NumberFormat from 'react-number-format';
import { VehicleAuctionActionCreators } from '../../store/actions/VehicleAuction';
import { AppUser } from '../../interfaces/AppUser';
import { IReferential } from '../../interfaces/IReferential';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import FileSaver from 'file-saver';
import { ScaleLoader } from 'react-spinners';
import { EInvoiceStatusEnum, InvoiceTypeCodeEnum, MavoVehicleInvoiceStatusEnum, ReportTypeCode, RoleClaimsEnum } from '../../helpers/Constants';
import {MuiThemeProvider, createMuiTheme } from '@material-ui/core';

class LocalizedUtils extends MomentUtils {
  // dateFormat = "DD MM YYYY";
}

type VoInvoiceHistoryProps = {
  appState: AppState;
  mavoVehicleState: MavoVehicleState
} &
  typeof MavoVehicleActionCreators & typeof VehicleAuctionActionCreators &
  ProviderContext &
  RouteComponentProps;

interface IVoInvoiceHistoryState {
  invoicesList: MavoVoInvoicesList;
  selectedAgentEntities: AppUser[];
  agentList: AppUser[];
  statusEntities: IReferential[];
  selectedStatusEntities: IReferential[];
  selectedStartDate: Date | null;
  selectedEndDate: Date | null;
  invoiceNumber: string;
  selectedTab: number;
  isLoading: boolean;
  isLoadingInvoices: boolean;
  count: number;
  page: number;
  pageSize: number;
  executing: boolean;
  selectedInvoice: MavoVehicleInvoice | null;
  isLoadingExport: boolean;
  isLoadingNotice: boolean;
  isLoadingInvoice: boolean;
  isLoadingBackToStock: boolean;
  isLoadingEInvoice: boolean;
  isLoadingResendInvoice: boolean;
}

class VoInvoiceHistory extends React.PureComponent<VoInvoiceHistoryProps, IVoInvoiceHistoryState> {
  private cbContext!: AppContext;
  static contextType = ApplicationContext;

  state = {
    invoicesList: {} as MavoVoInvoicesList,
    selectedAgentEntities: this.props.appState!.user!.profile.role.includes(RoleClaimsEnum.MavoAgent) ? [this.props.appState.appUser] : [],
    agentList: [],
    provenanceEntities: [],
    selectedProvenanceEntities: [],
    statusEntities: [],
    selectedStatusEntities: [],
    selectedStartDate: null,
    selectedEndDate: null,
    invoiceNumber: '',
    selectedTab: 0,
    isLoading: false,
    isLoadingInvoices: false,
    count: 1,
    page: 0,
    pageSize: 10,
    executing: false,
    isDialogOpen: false,
    isOfferDialogOpen: false,
    selectedInvoice: null,
    isLoadingExport: false,
    isLoadingInvoice: false,
    isLoadingNotice: false,
    isLoadingBackToStock: false,
    isLoadingEInvoice: false,
    isLoadingResendInvoice: false
  } as IVoInvoiceHistoryState;

  public async componentDidMount() {
    this.setState({
      isLoading: true
    });

    const mavoInvoicesFilters = {
      agentIds: this.state.selectedAgentEntities.map((item) => item.id),
      statusIds: [],
      startDate: null,
      endDate: null,
      invoiceNumber: '',
      page: this.state.page + 1,
      pageSize: this.state.pageSize
    } as MavoVoInvoiceFilters;

    const [invoicesList, invoicesFilters] = await Promise.all([
      this.cbContext.vehicleService.GetMavoInvoices(mavoInvoicesFilters),
      this.cbContext.vehicleService.GetMavoInvoicesFilters({
        agentIds: this.state.selectedAgentEntities.map((item) => item.id),
        statusIds: [],
        startDate: null,
        endDate: null,
        invoiceNumber: '',
      } as MavoVoInvoiceFilters)
    ]);
    const createdByList =  await this.cbContext.appUserService.GetUsersInfo(invoicesFilters.agentList);
    
    this.setState(
      {
        invoicesList: invoicesList,
        count: invoicesList.total,
        page: invoicesList.page - 1,
        statusEntities: invoicesFilters.statusList,
        agentList: createdByList,
        isLoading: false
      }
    );
  }

  isInternal = (): boolean | null => {
    if (this.state.selectedTab === 0) {
      return null;
    }
    if (this.state.selectedTab === 1) {
      return false;
    }

    return true;
  };

  loadInvoices = async (page: number, rowsPerPage: number) => {
    this.setState({
      isLoadingInvoices: true,
      selectedInvoice: null 
    });

    const mavoInvoicesFilters = {
      statusIds: this.state.selectedStatusEntities.map((item) => item.id),
      agentIds: this.state.selectedAgentEntities.map((item) => item.id),
      startDate: this.state.selectedStartDate
      ? new Date(this.state.selectedStartDate.setHours(0, 0, 0, 0))
      : null,
      endDate: this.state.selectedEndDate
      ? new Date(this.state.selectedEndDate.setHours(23, 59, 59, 999))
      : null,
      invoiceNumber: this.state.invoiceNumber,
      page: page + 1,
      pageSize: rowsPerPage
    } as MavoVoInvoiceFilters;

    const invoicesList = await this.cbContext.vehicleService.GetMavoInvoices(mavoInvoicesFilters);
    
    this.setState({
      invoicesList: invoicesList,
      page: invoicesList.page - 1,
      pageSize: rowsPerPage,
      count: invoicesList.total,
      isLoadingInvoices: false
    });
  };

  makeSelectedInvoice =  (isSelected: boolean, id: number) => {
    const invoices = _.cloneDeep(this.state.invoicesList.invoices);
    const invoice = invoices.find((item) => item.id === id);
    if (invoice) {
      invoice.isSelected = isSelected;
    }

    if (isSelected) {
      invoices.forEach((item) => {
        if (item.id !== id) {
          item.isSelected = false;
        }   
      });
    }
    this.setState({
      invoicesList: {
        ...this.state.invoicesList,
        invoices: invoices
      },
      selectedInvoice: isSelected ? (invoice ? invoice : null): null
    });
  }

  getMuiTheme = () => createMuiTheme({
    overrides: {
      MuiTableCell: {
        head: {
          paddingLeft: "2px",
          paddingRight: "2px",
          paddingTop: "2px",
          paddingBottom: "2px"
        },
      },
      MUIDataTableBodyCell: {
        stackedCommon: {
          paddingLeft: "2px",
          paddingRight: "2px",
          paddingTop: "0px",
          paddingBottom: "0px",
          height: "40px !important"
        }
      },
      MuiCheckbox: {
        root: {
          padding: "0px", // Adjust the padding to make the checkbox smaller
        },
        colorSecondary: {
          '&.Mui-checked': {
            color: "#3d4977 !important",
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.03) !important'
            },
          },
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.03) !important'
          }
        }
      }
    } as any
  });

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'id',
        label: ' ',
        options: {
          sort: false,
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            const invoice = this.state.invoicesList.invoices.find((item) => item.id === tableMeta.rowData[0]);
            return (
              <Grid container direction="row">
                <Grid item xs={12}>
                  <div className="font-size-sm">
                    <FormControlLabel
                      className="m-2"
                      control={
                        <Checkbox
                          checked={invoice!.isSelected || false}
                          onChange={(e) => {
                            this.makeSelectedInvoice(e.target.checked, invoice!.id);
                          }}
                        ></Checkbox>
                        }
                        label=''
                    />
                  </div>
                </Grid>
              </Grid>
            );
          }
        }
      },
      {
        name: 'mavoVehicle.vin',
        label: this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_VIN_CODE', 'VIN'),
        options: {
          sort: false
        }
      },
      {
        name: 'mavoVehicle.make.displayName',
        label: this.cbContext.translatorService.Tranlate('VEHICLES_VEHICLE_MAKE', 'Marca'),
        options: {
          sort: false
        }
      },
      {
        name: 'mavoVehicle.model.displayName',
        label: this.cbContext.translatorService.Tranlate('VEHICLES_VEHICLE_MODEL', 'Model'),
        options: {
          sort: false
        }
      },     
      {
        name: 'mavoVehicle.fabricationYear',
        label: this.cbContext.translatorService.Tranlate(
          'MAVO_VEHICLE_INVOICE_FABRICATION_YEAR',
          'An fabr'
        ),
        options: {
          sort: false
        }
      }, 
      {
        name: 'price',
        label: this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_INVOICE_RON_PRICE', 'Pret vanzare (lei)'),
        options: {
          sort: false,
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            const invoice = this.state.invoicesList.invoices.find((item) => item.id === tableMeta.rowData[0]);
            return (
              <Grid container direction="row">
                <Grid item xs={12}>
                  {invoice != null ? (invoice.price).toFixed(2) : null}
                </Grid>
              </Grid>
            )
          }
        }
      },
      {
        name: 'invoiceNumber',
        label: this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_INVOICE_NUMBER', 'Nr factura'),
        options: {
          sort: false
        }
      },
      {
        name: 'invoiceDate',
        label: this.cbContext.translatorService.Tranlate(
          'MAVO_VEHICLES_VEHICLE_INVOICE_DATE',
          'Data factura'
        ),
        options: {
          sort: false,
          customBodyRender: (value: any) => {
            return (
              <Grid container direction="row">
                <Grid item xs={12}>
                  <div className="font-size-sm">
                    {value
                      ? moment
                          .utc(value)
                          .local()
                          .format(this.props.appState.dateFormat)
                      : null}
                  </div>
                </Grid>
              </Grid>
            );
          }
        }
      }, 
      {
        name: 'invoiceStatus.displayName',
        label: this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_INVOICE_STATE', 'Stare factura'),
        options: {
          sort: false
        }
      },
      {
        name: 'anafIndexUpload',
        label: this.cbContext.translatorService.Tranlate('MAVO_INVOICE_ANAF_INDEX', 'Index Anaf'),
        options: {
          sort: false
        }
      },
      {
        name: 'anafDownloadId',
        label: this.cbContext.translatorService.Tranlate('MAVO_INVOICE_ANAF_DOWNLOAD', 'Id descarcare Anaf'),
        options: {
          sort: false
        }
      },
      {
        name: 'anafStatus',
        label: this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_E_INVOICE_STATE', 'Stare E-factura'),
        options: {
          sort: false,
          customBodyRender: (anafStatus: IReferential | null) => {
            return anafStatus ? anafStatus.displayName : null;
          }
        }
        
      }
    ];

    return columns;
  };

  sortFunction = (a: any, b: any) => {
    return a.order - b.order;
  };

  onTabChange = (event: any, newValue: number) => {
    this.setState(
      {
        selectedTab: newValue,
        page: 0
      },
      async () => await this.loadInvoices(0, this.state.pageSize)
    );
  };



  renderSearchButtons() {
    return (
      <div className="row m-0 mt-3">
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => this.loadInvoices(this.state.page, this.state.pageSize)}
          >
            {this.cbContext.translatorService.Tranlate(
              'MAVO_VEHICLES_VEHICLE_SEARCH',
              'Cautare'
            )}
          </Button>
        </div>
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => this.resetFilters()}
          >
            {this.cbContext.translatorService.Tranlate(
              'MAVO_VEHICLES_VEHICLE_RESET',
              'Reset'
            )}
          </Button>
        </div>
      </div>

    );
  }

  resetFilters = async () => {
    this.setState({
      selectedStatusEntities: [],
      selectedAgentEntities: this.hasRight(RoleClaimsEnum.MavoAgent) ? this.state.selectedAgentEntities : [],
      selectedStartDate: null,
      selectedEndDate: null,
      invoiceNumber: ''
    }, async () => {
      await this.loadInvoices(this.state.page, this.state.pageSize);
    });
  };

  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={2}
      />
    );
  };
  
  handleAgentChange = (newValue: any[]) => {
    this.setState(
      {
        selectedAgentEntities: newValue
      }
    );
  };

  handleStatusEntitiesChange = (newValue: any[]) => {
    this.setState(
      {
        selectedStatusEntities: newValue
      }
    );
  };

  handleStartDateChange = async (date: MaterialUiPickersDate | null, value?: string | null) => {
    if (date === null || !date.isValid) {
      return;
    }
    this.setState(
      {
        selectedStartDate: date!.toDate(),
        isLoading: false
      }
    );
  };

  handleEndDateChange = async (date: MaterialUiPickersDate | null, value?: string | null) => {
    if (date === null || !date.isValid) {
      return;
    }

    this.setState(
      {
        selectedEndDate: date!.toDate(),
        isLoading: false
      }
    );
  };

  renderFilters = () => {
    return (
      <div className="my-1">
        <ValidatorForm onSubmit={noop}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                id="agent"
                className="m-0 mt-2"
                size="small"
                multiple
                options={this.state.agentList.sort(function (a, b) {
                  return a.userName.localeCompare(b.userName);
                })}
                value={this.state.selectedAgentEntities}
                onChange={(e: any, newValue: any | null) => this.handleAgentChange(newValue)}
                getOptionLabel={(option: AppUser) => option.userName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="agent"
                    value={this.state.selectedAgentEntities}
                    label={this.cbContext.translatorService.Tranlate(
                      'MAVO_ORDER_AGENT',
                      'Agent'
                    )}
                    fullWidth
                  />
                )}
              />
              <MuiPickersUtilsProvider
                libInstance={moment}
                utils={LocalizedUtils}
                locale={this.props.appState.language}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  className="m-0 mt-1"
                  disableToolbar
                  variant="inline"
                  format={this.props.appState.dateFormat.toUpperCase()}
                  margin="normal"
                  label={this.cbContext.translatorService.Tranlate('AUCTIONS_FILTER_START_DATE', 'Data start')}
                  value={this.state.selectedStartDate}
                  onChange={this.handleStartDateChange}
                  autoOk={true}
                  // minDate={activeButton === 1 ? new Date() : this.state.minDate}
                />
              </MuiPickersUtilsProvider> 

              <div className="mt-2">
                <TextValidator
                  fullWidth
                  id="invoiceNumber"
                  name="invoiceNumber"
                  placeholder={this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_INVOICE_NUMBER', 'Nr factura')}
                  value={this.state.invoiceNumber}
                  onChange={(e: any) => {
                    this.setState({
                      invoiceNumber: e.target.value
                    });
                  }}
                  validators={['required']}
                  label={this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_INVOICE_NUMBER', 'Nr factura')}
                />
              </div> 
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="status"
                size="small"
                className="m-0 mt-2"
                multiple
                options={this.state.statusEntities.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedStatusEntities}
                onChange={(e: any, newValue: any | null) =>
                  this.handleStatusEntitiesChange(newValue)
                }
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="statusEntities"
                    value={this.state.selectedStatusEntities}
                    label={this.cbContext.translatorService.Tranlate(
                      'MAVO_INVOICE_STATUS',
                      'Stare'
                    )}
                    fullWidth
                  />
                )}
              />
              <MuiPickersUtilsProvider
                libInstance={moment}
                utils={LocalizedUtils}
                locale={this.props.appState.language}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  className="m-0 mt-1"
                  disableToolbar
                  variant="inline"
                  format={this.props.appState.dateFormat.toUpperCase()}
                  margin="normal"
                  label={this.cbContext.translatorService.Tranlate('AUCTIONS_FILTER_END_DATE', 'Data stop')}
                  value={this.state.selectedEndDate}
                  onChange={this.handleEndDateChange}
                  autoOk={true}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </ValidatorForm>
      </div>
    );
  };

  renderAgentMavoFilters = () => {
    return (
      <div className="my-1">
        <ValidatorForm onSubmit={noop}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider
                libInstance={moment}
                utils={LocalizedUtils}
                locale={this.props.appState.language}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  className="m-0 mt-1"
                  disableToolbar
                  variant="inline"
                  format={this.props.appState.dateFormat.toUpperCase()}
                  margin="normal"
                  label={this.cbContext.translatorService.Tranlate('AUCTIONS_FILTER_START_DATE', 'Data start')}
                  value={this.state.selectedStartDate}
                  onChange={this.handleStartDateChange}
                  autoOk={true}
                  // minDate={activeButton === 1 ? new Date() : this.state.minDate}
                />
              </MuiPickersUtilsProvider> 

              <TextValidator
                fullWidth
                className="mt-1"
                id="invoiceNumber"
                name="invoiceNumber"
                placeholder={this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_INVOICE_NUMBER', 'Nr factura')}
                value={this.state.invoiceNumber}
                onChange={(e: any) => {
                  this.setState({
                    invoiceNumber: e.target.value
                  });
                }}
                validators={['required']}
                label={this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_INVOICE_NUMBER', 'Nr factura')}
              />
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider
                libInstance={moment}
                utils={LocalizedUtils}
                locale={this.props.appState.language}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  className="m-0 mt-1"
                  disableToolbar
                  variant="inline"
                  format={this.props.appState.dateFormat.toUpperCase()}
                  margin="normal"
                  label={this.cbContext.translatorService.Tranlate('AUCTIONS_FILTER_END_DATE', 'Data stop')}
                  value={this.state.selectedEndDate}
                  onChange={this.handleEndDateChange}
                  autoOk={true}
                />
              </MuiPickersUtilsProvider>

              <Autocomplete
                id="status"
                size="small"
                className="m-0 mt-1"
                multiple
                options={this.state.statusEntities.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedStatusEntities}
                onChange={(e: any, newValue: any | null) =>
                  this.handleStatusEntitiesChange(newValue)
                }
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="statusEntities"
                    value={this.state.selectedStatusEntities}
                    label={this.cbContext.translatorService.Tranlate(
                      'MAVO_INVOICE_STATUS',
                      'Stare'
                    )}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </ValidatorForm>
      </div>
    );
  };

  tableOptions = () => {
    return {
      sort: true,
      filter: false,
      search: false,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.cbContext.translatorService),
      elevation: 0,
      serverSide: true,
      count: this.state.count,
      rowsPerPage: this.state.pageSize,
      page: this.state.page,
      onTableChange: async (action: any, tableState: any) => {
        switch (action) {
          case 'changePage':
            await this.loadInvoices(tableState.page, tableState.rowsPerPage);
            break;
          case 'changeRowsPerPage':
            await this.loadInvoices(tableState.page, tableState.rowsPerPage);
            break;
          case 'sort':
            break;
          default:
        }
      }
    };
  };

  handleBackToStock = async () => {
    if (!this.state.selectedInvoice) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('PLEASE_SELECT_LINE', 'Va rugam sa selectati o linie!'), {
        variant: 'warning'
      });
      return;
    }

    if (!this.state.selectedInvoice.invoiceStatus) {
      return;
    }

    if (this.state.selectedInvoice.invoiceStatus!.code === MavoVehicleInvoiceStatusEnum.STORNO.toString() 
      || this.state.selectedInvoice.invoiceStatus!.code === MavoVehicleInvoiceStatusEnum.STORNATA.toString()) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('INVOICE_BACK_TO_STOCK_WARNING', 'Operatia este disponibila doar pentru facturile in stare Valida!'), {
        variant: 'warning'
      });
      return;
    }

    this.setState({isLoadingBackToStock: true});
    try {
      const request = {
        mavoVehicleOrderId: this.state.selectedInvoice.mavoVehicleOrderId,
        userId: this.state.selectedInvoice.mavoVehicleOrder.userId ?? null,
        invoiceId: this.state.selectedInvoice.id
      } as MavoVehicleCreateInvoiceRequestDto;
      
      const response = await this.cbContext.vehicleService.CancelInvoiceMavoVehicle(ReportTypeCode.RENAULT_FACTURA_LIVRARE, request);

      const eInvoiceResponse = await this.cbContext.vehicleService.CreateEInvoice({
        orderId: this.state.selectedInvoice.mavoVehicleOrderId,
        invoiceId: response.id,
        invoiceTypeCode: InvoiceTypeCodeEnum.FACTURA.toString()
      } as CreateEInvoiceRequest);
      if (eInvoiceResponse && eInvoiceResponse.statusMessage) {
        this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate(eInvoiceResponse.statusMessage, eInvoiceResponse.statusMessage), {
          variant: 'warning'
        });
      }

      this.setState({isLoadingBackToStock: false});

      this.loadInvoices(this.state.page, this.state.pageSize);

      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch {
      this.setState({isLoadingBackToStock: false});

      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  }

  handlePrintNotice = async () => {
    if (!this.state.selectedInvoice) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('PLEASE_SELECT_LINE', 'Va rugam sa selectati o linie!'), {
        variant: 'warning'
      });
      return;
    }

    if (!this.state.selectedInvoice.invoiceStatus) {
      return;
    }

    if (this.state.selectedInvoice.invoiceStatus!.code === MavoVehicleInvoiceStatusEnum.STORNO.toString()) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('INVOICE_PRINT_NOTICE_FOR_STORNO_INVOICE', 'Se pot tipari doar avizele care au factura Valida sau Stornata!'), {
        variant: 'warning'
      });
      return;
    }

    this.setState({ isLoadingNotice: true });
    try {
      const fileBlob = await this.cbContext.vehicleService.PrintMavoVehicleNoticeBasedInvoiceId(this.state.selectedInvoice.id);
      const file = new File([fileBlob], this.cbContext.translatorService.Tranlate('PRINT_NOTICE_NAME', 'Avizare plata bancara') + '.pdf', { type: 'application/pdf' });
      this.setState({ isLoadingNotice: false });

      FileSaver.saveAs(fileBlob, "AvizarePlataBancara" + '.pdf');
      const url = URL.createObjectURL(file);
      window.open(url, '_blank');
    } catch {
      this.setState({ isLoadingNotice: false });
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  }

  handlePrintInvoice = async () => {
    if (!this.state.selectedInvoice) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('PLEASE_SELECT_LINE', 'Va rugam sa selectati o linie!'), {
        variant: 'warning'
      });
      return;
    }
    if (!this.state.selectedInvoice.invoiceStatus) {
      return;
    }

    if (this.state.selectedInvoice.invoiceStatus!.code === MavoVehicleInvoiceStatusEnum.STORNATA.toString()) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('INVOICE_PRINT_FOR_STORNATA_INVOICE', 'Nu pot fi tiparite decat facturi cu stare Valida sau Storno!'), {
        variant: 'warning'
      });
      return;
    }

    const isStorno = this.state.selectedInvoice.invoiceStatus!.code === MavoVehicleInvoiceStatusEnum.STORNO.toString();
    this.setState({ isLoadingInvoice: true });
    try {
      const fileBlob = await this.cbContext.vehicleService.PrintInvoice(this.state.selectedInvoice.id);
      const file = new File([fileBlob], this.cbContext.translatorService.Tranlate('PRINT_INVOICE_NAME', 'Factura livrare') + '.pdf', { type: 'application/pdf' });
      this.setState({ isLoadingInvoice: false });

      FileSaver.saveAs(fileBlob, isStorno ? "FacturaLivrareStorno" : "FacturaLivrare" + '.pdf');
      const url = URL.createObjectURL(file);
      window.open(url, '_blank');
    } catch {
      this.setState({ isLoadingInvoice: false });
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  }

  handlePrintEInvoice = async () => {
    if (!this.state.selectedInvoice) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('PLEASE_SELECT_LINE', 'Va rugam sa selectati o linie!'), {
        variant: 'warning'
      });
      return;
    }
    if (!this.state.selectedInvoice.invoiceStatus) {
      return;
    }

    if (this.state.selectedInvoice.invoiceStatus!.code === MavoVehicleInvoiceStatusEnum.STORNATA.toString()) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('INVOICE_PRINT_FOR_STORNATA_INVOICE', 'Nu pot fi tiparite decat facturi cu stare Valida sau Storno!'), {
        variant: 'warning'
      });
      return;
    }
    
    if (!this.state.selectedInvoice.resultPath) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('INVOICE_NO_DOWNLOAD_ID', 'Nu exista id de descarcare anaf!'), {
        variant: 'warning'
      });
      return;
    }

    this.setState({ isLoadingEInvoice: true });
    try {
      const request = {
        filePath: this.state.selectedInvoice.resultPath
      } as DownloadResultsRequest;
      const response = await this.cbContext.vehicleService.DownloadResults(request);
      const byteArray = Uint8Array.from(atob(response.byteContent), char => char.charCodeAt(0));
      const file = new Blob([byteArray], { type: 'application/zip' });
      FileSaver.saveAs(file, response.name);
      this.setState({ isLoadingEInvoice: false });    
    } catch {
      this.setState({ isLoadingEInvoice: false });
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }     
  }

  handleSendToEInvoice = async () => {
    if (!this.state.selectedInvoice) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('PLEASE_SELECT_LINE', 'Va rugam sa selectati o linie!'), {
        variant: 'warning'
      });
      return;
    }

    if (!this.state.selectedInvoice.invoiceStatus) {
      return;
    }

    if (this.state.selectedInvoice.invoiceStatus!.code === MavoVehicleInvoiceStatusEnum.STORNATA.toString()) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('INVOICE_PRINT_FOR_STORNATA_INVOICE', 'Nu pot fi tiparite decat facturi cu stare Valida sau Storno!'), {
        variant: 'warning'
      });
      return;
    }

    this.setState({ isLoadingInvoices: true });
    try {
      const eInvoiceResponse = await this.cbContext.vehicleService.CreateEInvoice({
        invoiceId: this.state.selectedInvoice.id,
        orderId: this.state.selectedInvoice.mavoVehicleOrderId,
        invoiceTypeCode: InvoiceTypeCodeEnum.FACTURA_CORECTATA.toString()
      } as CreateEInvoiceRequest);
      if (eInvoiceResponse.statusMessage) {
        this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate(eInvoiceResponse.statusMessage, eInvoiceResponse.statusMessage), {
          variant: 'warning'
        });
      }
      
      this.loadInvoices(this.state.page, this.state.pageSize);
      this.setState({ isLoadingInvoices: false });
    } catch {
      this.setState({ isLoadingInvoices: false });
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }     
  }

  handleExportInvoices = async () => {
    try {
      this.setState({
        isLoadingExport: true
      });
      const mavoInvoicesFilters = {
        agentIds: this.state.selectedAgentEntities.map((item) => item.id),
        statusIds: this.state.selectedStatusEntities.map((item) => item.id),
        startDate: this.state.selectedStartDate
        ? new Date(this.state.selectedStartDate.setHours(0, 0, 0, 0))
        : null,
        endDate: this.state.selectedEndDate
        ? new Date(this.state.selectedEndDate.setHours(23, 59, 59, 999))
        : null,
        invoiceNumber: this.state.invoiceNumber
      } as MavoVoInvoiceExportFilters;
      const fileBlob = await this.cbContext.vehicleService.ExportInvoices(mavoInvoicesFilters);
      FileSaver.saveAs(fileBlob, 'Export VO Facturat' + '.xlsx');
      this.setState({
        isLoadingExport: false
      });
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (err: any) {
      this.setState({
        isLoadingExport: false
      });
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  }

  public hasRight = (right: string): boolean => {
    return this.props.appState!.user!.profile.role.includes(right);
  };

  public disabledResendInvoice = () : boolean => {
    if (!this.state.selectedInvoice) {
      return true;
    }

    if (this.state.selectedInvoice.anafStatus && (this.state.selectedInvoice.anafStatus.code === EInvoiceStatusEnum.PENDING.toString() || this.state.selectedInvoice.anafStatus.code === EInvoiceStatusEnum.VERIFICATION_SUCCESS.toString())) {
      return true;
    }

    return false;
  };

  public disabledPrintEInvoice = () : boolean => {
    if (!this.state.selectedInvoice) {
      return true;
    }

    if (!this.state.selectedInvoice.anafStatus) {
      return true;
    }

    return false; 
  }

  renderMavoInvoicesButtons() {
    return (
      <div className="row m-0 mt-3">
        <div className='mb-3 mr-2'>
          {!this.hasRight(RoleClaimsEnum.MavoAgent) ? (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={this.handleBackToStock}
              disabled={this.state.isLoadingBackToStock}
            >
              {this.state.isLoadingBackToStock && (
                <CircularProgress
                  size={16}
                  style={{ marginRight: 5 }}
                />
              )}
              {this.cbContext.translatorService.Tranlate(
                'RETURN_STOCK',
                'Retur Stoc'
              )}
            </Button>
          ) : ''}
        </div>
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handlePrintNotice}
            disabled={this.state.isLoadingNotice}
          >
            {this.state.isLoadingNotice && (
              <CircularProgress
                size={16}
                style={{ marginRight: 5 }}
              />
            )}
            {this.cbContext.translatorService.Tranlate(
              'PRINT_NOTICE',
              'Tiparire aviz'
            )}
          </Button>
        </div>
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handlePrintInvoice}
            disabled={this.state.isLoadingInvoice}
          >
            {this.state.isLoadingInvoice && (
              <CircularProgress
                size={16}
                style={{ marginRight: 5 }}
              />
            )}
            {this.cbContext.translatorService.Tranlate(
              'PRINT_INVOICE',
              'Tiparire factura'
            )}
          </Button>
        </div>
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handlePrintEInvoice}
            disabled={this.state.isLoadingEInvoice  || this.disabledPrintEInvoice()}
          >
            {this.state.isLoadingEInvoice && (
              <CircularProgress
                size={16}
                style={{ marginRight: 5 }}
              />
            )}
            {this.cbContext.translatorService.Tranlate(
              'PRINT_EINVOICE',
              'Tiparire e-factura'
            )}
          </Button>
        </div>
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleSendToEInvoice}
            disabled={this.state.isLoadingResendInvoice || this.disabledResendInvoice()}
          >
            {this.state.isLoadingResendInvoice && (
              <CircularProgress
                size={16}
                style={{ marginRight: 5 }}
              />
            )}
            {this.cbContext.translatorService.Tranlate(
              'RESEND_EINVOICE',
              'Reincarca in e-factura'
            )}
          </Button>
        </div>
        <div className='mb-3 mr-2'>
          {!this.hasRight(RoleClaimsEnum.MavoAgent) ?  (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={this.handleExportInvoices}
            >
              {this.cbContext.translatorService.Tranlate(
                'EXPORT_DATA',
                'Export'
              )}
            </Button>
          ) : ''}
        </div>
      </div>
    );
  }


  public render() {
    this.cbContext = this.context as AppContext;

    if (this.state.isLoading) {
      return <Loader />;
    }

    return (
      <React.Fragment>
        <Card>
          <AppBar position="static" elevation={0} color="secondary">
            <Tabs
              TabIndicatorProps={{
                style: {
                  height: '4px'
                }
              }}
              value={this.state.selectedTab}
              onChange={this.onTabChange}
              variant="fullWidth"
              centered={true}
              indicatorColor="primary"
              aria-label="simple tabs example"
            >
              <Tab
                label={this.cbContext.translatorService.Tranlate('VO_INVOICE_TITLE', 'Cautare facturi VO')}
                />
            </Tabs>
          </AppBar>

          <div>
            <TabPanel value={this.state.selectedTab} index={0}>
              <div className="m-3">
                {this.renderMavoInvoicesButtons()}
                <div className="d-flex flex-row text-center flex-wrap justify-content-center">
                  <ScaleLoader color={'var(--primary)'} loading={this.state.isLoadingExport} />
                </div>
                {this.hasRight(RoleClaimsEnum.MavoAgent) ? this.renderAgentMavoFilters() : this.renderFilters()}
                {this.renderSearchButtons()}
                {this.state.isLoadingInvoices ? (
                  <Loader />
                ) : (
                  <MuiThemeProvider theme={this.getMuiTheme()}>
                    <MUIDataTable
                      title=""
                      data={this.state.invoicesList.invoices}
                      columns={this.getColumns()}
                      options={this.tableOptions()}
                    />
                  </MuiThemeProvider>
                )}       
              </div>
            </TabPanel>
          </div>
        </Card> 
      </React.Fragment>
    );
  }
}
export default connect(
  (state: ApplicationState) => ({
    appState: state.app,
    mavoVehicleState: state.mavoVehicle
  }),
{ ...MavoVehicleActionCreators }
)(withSnackbar(VoInvoiceHistory as any));