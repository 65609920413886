import * as React from 'react';
import { ApplicationState, AppState, CaseSettingsState } from '../../store';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { Button, Grid, CardContent, Typography} from '@material-ui/core';
import { withSnackbar, ProviderContext } from 'notistack';
import _ from 'lodash';
import {  TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { ScaleLoader } from 'react-spinners';
import { Card } from 'reactstrap';
import { GeneralNotifySupportRequest, NotificationClient } from '../../interfaces/Case';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { NotifySupportTemplateEnum } from '../../helpers/Constants';
import DOMPurify from 'dompurify';

type GeneralNotificationProps = {
    appState: AppState;
    caseSettingsState: CaseSettingsState;
  } & ProviderContext &
    RouteComponentProps;

interface IGeneralNotificationState {
  isLoading: boolean;
  clientData: NotificationClient;
}

class GeneralNotification extends React.PureComponent<GeneralNotificationProps, IGeneralNotificationState> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  static contextType = ApplicationContext;

  state = {
    isLoading: false,
    selectedOrganization: null,
    clientData: {
      firstName: '',
      lastName: '',
      companyName: this.props.appState!.appUser!.organization!.name,
      companyId: this.props.appState!.appUser!.organizationId,
      email: this.props.appState!.appUser!.email,
      phoneNumber: '',
      comment: '',
    } as NotificationClient
  }  as IGeneralNotificationState;

  public async componentDidMount() {
    this.setState(
      {
        isLoading: false
      },
      async () => {
        await this.loadData();
      }
    );
  }

  loadData = async () => {
    this.setState({
      isLoading: false
    });
  }

  onChange =
    (fieldName: string) => (e: any) => {
      this.setState({
        clientData: {
          ...this.state.clientData,
          [fieldName]: DOMPurify.sanitize(e.target.value)
        }
      })
  };

  notifySupport = async () => {
    try {
      this.setState({ isLoading: true });
      const request = {
        template: NotifySupportTemplateEnum.GENERAL_NOTIFY_SUPPORT,
        client: this.state.clientData
      } as GeneralNotifySupportRequest;

      await this.caseService.SendGeneralNotifySupport(request);

      this.setState({ isLoading: false });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (ex) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  renderForm = () => {
    return (
      <ValidatorForm
        onSubmit={() => {
          this.notifySupport();
        }}
      >
      <div className="text-center">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextValidator
              value={this.state.clientData.lastName || ''}
              id="lastName"
              name="lastName"
              onChange={(e: any) => {this.onChange('lastName')(e)}}
              fullWidth
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
              label={this.translatorService.Tranlate('NOTIFICATION_CLIENT_DATA_FORM_NAME', 'Nume')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextValidator
              value={this.state.clientData.firstName || ''}
              id="firstName"
              name="firstName"
              onChange={(e: any) => {this.onChange('firstName')(e)}}
              fullWidth
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
              label={this.translatorService.Tranlate('NOTIFICATION_CLIENT_DATA_FORM_FIRST_NAME', 'Prenume')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextValidator
              value={this.state.clientData.companyName || ''}
              id="companyName"
              name="companyName"
              onChange={(e: any) => {this.onChange('companyName')(e)}}
              fullWidth
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
              label={this.translatorService.Tranlate('NOTIFICATION_CLIENT_DATA_FORM_ORGANIZATION_NAME', 'Nume Companie')}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextValidator
              fullWidth
              id="email"
              name="email"
              placeholder={this.translatorService.Tranlate('NOTIFICATION_CLIENT_DATA_FORM_EMAIL', 'E-mail')}
              value={this.state.clientData.email || ''}
              onChange={(e: any) => {this.onChange('email')(e)}}
              validators={['isEmail']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_EMAIL', 'E-mail invalid')
              ]}
              label={this.translatorService.Tranlate('NOTIFICATION_CLIENT_DATA_FORM_EMAIL', 'E-mail')}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextValidator
              fullWidth
              id="phoneNumber"
              name="phoneNumber"
              placeholder={this.translatorService.Tranlate('NOTIFICATION_CLIENT_DATA_FORM_PHONE', 'Telefon')}
              value={this.state.clientData.phoneNumber || ''}
              onChange={(e: any) => {this.onChange('phoneNumber')(e)}}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
              label={this.translatorService.Tranlate('NOTIFICATION_CLIENT_DATA_FORM_PHONE', 'Telefon')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextValidator
              fullWidth
              id="comment"
              name="comment"
              className='mt-2'
              placeholder={this.translatorService.Tranlate(
                'NOTIFICATION_CLIENT_DATA_FORM_COMMENT_PLACEHOLDER',
                'Mesaj ...'
              )}
              label={this.translatorService.Tranlate(
                'NOTIFICATION_CLIENT_DATA_FORM_COMMENT',
                'Mesaj'
              )}
              value={this.state.clientData.comment || ''}
              onChange={(e: any) => this.onChange('comment')(e)}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
              multiline
              variant="outlined"
              rows={4}
            />
          </Grid>
        </Grid>
      </div>
      <div className="d-flex flex-row text-center flex-wrap justify-content-center">
        <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
      </div>
      <div className="m-3 text-center">
        <Button
          className="m-2"
          variant="contained"
          color="primary"
          type="submit"
          disabled={this.state.isLoading}
          >
          {this.translatorService.Tranlate('SAVE', 'Salveaza')}
        </Button>
      </div> 
    </ValidatorForm>
    );
  };

  public hasRight = (right: string): boolean => {
    return this.props.appState!.user!.profile.role.includes(right);
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;

    return (
      <React.Fragment>
        <div className="d-flex justify-content-center">
          <div className="p-1 w-50">
            <Card className="card-box w-100 shadow-none">
              <div className="card-header text-center">
                <div className="card-header--title">
                  <Typography variant="h4">
                    {this.translatorService.Tranlate(
                      'GENERAL_NOTIFICATION_TITLE',
                      'Notifica Suport'
                    )}
                  </Typography>
                </div>
              </div>
              <CardContent>
                {this.renderForm()}
              </CardContent>
            </Card>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app,
    caseSettingsState: state.caseSettings
  }),
  null
)(withSnackbar(GeneralNotification as any));
