import * as React from 'react';
import {
  ApplicationState,
  AppState,
  CaseSettingsState
} from '../../store';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { Button, Card, Grid } from '@material-ui/core';
import moment from 'moment';
import { withSnackbar, ProviderContext } from 'notistack';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ValidatorForm } from 'react-material-ui-form-validator';
import FileSaver from 'file-saver';
import { LanguageCode } from '../../helpers/Constants';
import { ScaleLoader } from 'react-spinners';
import { NewBillingReportFilters } from '../../interfaces/Case';

type NewBillingReportProps = {
  appState: AppState;
  caseSettingsState: CaseSettingsState;
} & ProviderContext &
  RouteComponentProps;

interface INewBillingReportState {
  isLoading: boolean;
  filterStartDate: Date | null;
  filterEndDate: Date | null;
  startDateError: string;
  endDateError: string;
}

class LocalizedUtils extends MomentUtils {
  // dateFormat = "DD MM YYYY";
}

class NewBillingReport extends React.PureComponent<NewBillingReportProps, INewBillingReportState> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  static contextType = ApplicationContext;

  state = {
    isLoading: false,
    filterStartDate: null,
    filterEndDate: null,
    startDateError: '',
    endDateError: ''
  } as INewBillingReportState;

  public async componentDidMount() {
    this.setState({
      isLoading: false
    });
  }

  public hasRight = (right: string): boolean => {
    return this.props.appState!.user!.profile.role.includes(right);
  };

  exportInfo = async () => {
    if (this.state.startDateError || this.state.endDateError) {
      return;
    }
    const language =
      this.props.appState.language == LanguageCode.RO ? LanguageCode.RO : LanguageCode.EN;

    try {
      this.setState({ isLoading: true });
      const formatedStartDate = this.state.filterStartDate
        ? moment(this.state.filterStartDate).format('DD.MM.YYYY')
        : null;
      const formatedEndDate = this.state.filterEndDate
        ? moment(this.state.filterEndDate).format('DD.MM.YYYY')
        : null;
      const requestPayload = {
        startDate: this.state.filterStartDate
          ? new Date(this.state.filterStartDate.setHours(0, 0, 0, 0))
          : null,
        endDate: this.state.filterEndDate
          ? new Date(this.state.filterEndDate.setHours(23, 59, 59, 999))
          : null
      } as NewBillingReportFilters;

      const fileBlob = await this.caseService.GetNewBillingReport(requestPayload);
      FileSaver.saveAs(
        fileBlob,
        language == LanguageCode.RO
          ? 'Raport Carfix '  + formatedStartDate + '-' + formatedEndDate + '.xlsx'
          : 'Raport Carfix '  + formatedStartDate + '-' + formatedEndDate + '.xlsx'
      );

      this.setState({ isLoading: false });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (err: any) {
      this.setState({ isLoading: false });
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  validateDates = (startDate: Date | null, endDate: Date | null): void => {
    /*
    Validate start date
    */
    let startDateError = '';
    if (!startDate) {
      startDateError = this.translatorService.Tranlate(
        'VALIDATORS_REQUIRED',
        'Campul este obligatoriu'
      );
    } else {
      const currentDate = moment().format('YYYY-MM-DD');
      if (endDate && startDate > endDate) {
        startDateError = this.translatorService.Tranlate(
          'EXPORT_START_DATE_ERROR',
          'De la trebuie sa fie mai mica decat pana la'
        );
      }
    }

    /*
    Validate end date
    */

    let endDateError = '';
    if (!endDate) {
      endDateError = this.translatorService.Tranlate(
        'VALIDATORS_REQUIRED',
        'Campul este obligatoriu'
      );
    }

    this.setState({
      startDateError: startDateError,
      endDateError: endDateError
    });
  };

  handlefilterStartDateChange = async (date: MaterialUiPickersDate | null) => {
    if (date === null || !date.isValid) {
      return;
    }
    this.setState({
      filterStartDate: date === null || !date.isValid ? null : date!.toDate(),
      startDateError: ''
    });
  };

  handlefilterEndDateChange = async (date: MaterialUiPickersDate | null) => {
    if (date === null || !date.isValid) {
      return;
    }
    this.setState({
      filterEndDate: date === null || !date.isValid ? null : date!.toDate(),
      endDateError: ''
    });
  };

  renderFilters = () => {
    return (
      <ValidatorForm
        onSubmit={() => {
          this.exportInfo();
        }}
      >
        <div className="m-3 text-center">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider
                libInstance={moment}
                utils={LocalizedUtils}
                locale={this.props.appState.language}
              >
                <DatePicker
                  fullWidth
                  className="mt-2 ml-2 pr-2"
                  openTo="date"
                  views={['year', 'month', 'date']}
                  variant="inline"
                  format={this.props.appState.dateFormat.toUpperCase()}
                  margin="normal"
                  id="date-picker-inline-from"
                  label={this.translatorService.Tranlate('ORDERS_DATE_LABEL_FROM', 'De la')}
                  value={this.state.filterStartDate}
                  onChange={this.handlefilterStartDateChange}
                  autoOk={true}
                  error={this.state.startDateError ? true : false}
                  helperText={this.state.startDateError}
                  required={true}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider
                libInstance={moment}
                utils={LocalizedUtils}
                locale={this.props.appState.language}
              >
                <DatePicker
                  fullWidth
                  className="mt-2 ml-2 pr-2"
                  openTo="date"
                  views={['year', 'month', 'date']}
                  variant="inline"
                  format={this.props.appState.dateFormat.toUpperCase()}
                  margin="normal"
                  id="date-picker-inline-to"
                  label={this.translatorService.Tranlate('ORDERS_DATE_LABEL_TO', 'Pana la')}
                  value={this.state.filterEndDate}
                  onChange={this.handlefilterEndDateChange}
                  autoOk={true}
                  error={this.state.endDateError ? true : false}
                  helperText={this.state.endDateError}
                  required={true}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <div className="m-3 text-center">
            <Button
              className="mt-3"
              variant="contained"
              color="primary"
              type="submit"
              onClick={() => {
                this.validateDates(this.state.filterStartDate, this.state.filterEndDate);
              }}
            >
              {this.translatorService.Tranlate('EXPORT_DATA', 'Export')}
            </Button>
          </div>
        </div>
      </ValidatorForm>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;

    return (
      <React.Fragment>
        <Card>
          <div>{this.renderFilters()}</div>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app,
    caseSettingsState: state.caseSettings
  }),
  null
)(withSnackbar(NewBillingReport as any));
