import React, { Fragment, ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  Organization,
  OrganizationType,
  OrganizationLevelType
} from '../../../interfaces/Organization';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import {
  Box,
  Switch,
  Button,
  Tooltip,
  IconButton,
  Card,
  CardContent,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  DialogContent
} from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import moment from 'moment';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import {
  OrganizationTypeCode,
  ReferentialCode,
  OrganizationLevelTypeCode
} from '../../../helpers/Constants';
import CloseIcon from '@material-ui/icons/Close';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Referentials from '../../../helpers/Referentials.json';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import { RouteComponentProps } from 'react-router';
import { IReferential } from '../../../interfaces/IReferential';

interface IHOOrganizationsState {
  organizations: Organization[];
  isLoading: boolean;
  selectedFilter: number;
  isDialogOpen: boolean;
  newHOOrg: Organization;
  organizationTypes: OrganizationType[];
}

type HOOrganizationsProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string }>;
class MavoHOOrganizations extends React.PureComponent<HOOrganizationsProps, IHOOrganizationsState> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  private neworg = {
    name: '',
    id: 0,
    gtId: this.props.appState.appUser!.gtId,
    organizationTypeId: 0,
    organizationType: null,
    organizationLevelTypeId: 1,
    parent: null,
    isActive: true,
    isMavoPartener: true
  } as Organization;

  state = {
    organizations: [],
    isLoading: false,
    selectedFilter: 0,
    isDialogOpen: false,
    newHOOrg: this.neworg,
    organizationTypes: [] as OrganizationType[]
  } as IHOOrganizationsState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadOrganizations();
      }
    );
  }

  loadOrganizations = async () => {
    const orgList = await this.organizationService.GetMavoHOOrganizations();

    this.setState({
      organizations: orgList,
      isLoading: false,
      newHOOrg: { ...this.neworg }
    });
  };

  filterOrganizations = (orgList: Organization[]): Organization[] => {
    if (this.state.selectedFilter === 1) {
      orgList = orgList.filter((item) => item.organizationType!.code === OrganizationTypeCode.GT);
    }
    if (this.state.selectedFilter === 2) {
      orgList = orgList.filter((item) => item.organizationType!.code !== OrganizationTypeCode.GT);
    }

    return orgList;
  };

  newHOOrg = async () => {
    const ref = Referentials.referential.find(
      (item) => item.code === ReferentialCode.OrganizationType
    );
    const orgTypes = await this.appReferentialService.Get(ref!.baseUrl);

    this.setState({
      isDialogOpen: true,
      organizationTypes: orgTypes.filter(
        (item) => item.isActive === true
      ) as any as OrganizationType[]
    });
  };

  onRowClick = async (e: any, id: string, value: string) => {
    this.props.history.push('/admin/mavo/hoorganizations/' + id, value);
  };

  filterChange = (event: React.ChangeEvent<any>, newValue: number) => {
    this.setState(
      { selectedFilter: newValue }
      // ,    async () => await this.loadOrganizations()
    );
  };

  handleIsActiveChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    tableMeta: MUIDataTableMeta
  ) => {
    const id = tableMeta.rowData[0];
    const newOrganizations = [...this.state.organizations];
    const updatedOrgIndex = newOrganizations.findIndex((o) => o.id === id);

    if (updatedOrgIndex >= 0) {
      newOrganizations[updatedOrgIndex] = {
        ...newOrganizations[updatedOrgIndex],
        isActive: event.target.checked
      };

      try {
        await this.organizationService.UpdateOrganization(newOrganizations[updatedOrgIndex]);

        this.setState({ organizations: newOrganizations });

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'name',
        label: this.translatorService.Tranlate(
          'ORGANIZATIONS_ORGANIZATION_NAME_HEADER',
          'Organizatie'
        ),
        options: {
          filter: false,
          customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
            return (
              <Button
                color="primary"
                className=" text-primary"
                onClick={(e) => this.onRowClick(e, tableMeta.rowData[0], value)}
              >
                <span>{value}</span>
              </Button>
            );
          }
        }
      },
      {
        name: 'organizationType',
        label: this.translatorService.Tranlate('ORGANIZATIONS_ORGANIZATION_TYPE_HEADER', 'Tip'),
        // customSort: (a:IReferential, b:IReferential) => { debugger; return a.name!.localeCompare(b.name!)},
        options: {
          sort: true,
          customSort: (a: IReferential, b: IReferential) => {
            debugger;
            return a.name!.localeCompare(b.name!);
          },
          customBodyRender: (value: OrganizationType) => {
            return value.displayName;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'companyNumber',
        label: this.translatorService.Tranlate('ORGANIZATIONS_COMPANY_NUMBER_HEADER', 'CUI'),
        options: {
          filter: false
        }
      },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate('USERS_DATE_MODIFICATION_HEADER', 'Data Modificare'),
        options: {
          filter: false,
          customBodyRender: (value: Date) =>
            moment.utc(value).local().format(this.props.appState.longDateFormat)
        } as MUIDataTableColumnOptions
      },
      {
        name: 'isActive',
        label: this.translatorService.Tranlate('USERS_IS_ACTIVE_HEADER', 'Activ'),
        options: {
          sortDirection: 'desc',
          filterOptions: {
            names: [
              this.translatorService.Tranlate('TABLE_IS_ACTIVE_COLUMN_ACTIVE', 'Activ'),
              this.translatorService.Tranlate('TABLE_IS_ACTIVE_COLUMN_INACTIVE', 'Inactiv')
            ],
            logic(isActive: any, filterVal: string[]) {
              const show =
                (filterVal.indexOf(this.names![0]) !== -1 && isActive === true) ||
                (filterVal.indexOf(this.names![1]) !== -1 && isActive === false);
              return !show;
            }
          },
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <Switch
                checked={value}
                onChange={(e) => this.handleIsActiveChange(e, tableMeta)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          }
        }  as MUIDataTableColumnOptions
      }
    ];

    return columns;
  };

  private handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: any;
    if (event.target.type === 'text') {
      value = event.currentTarget.value;
    }
    this.setState({
      newHOOrg: {
        ...this.state.newHOOrg,
        [event.target.id]: value
      }
    });
  };

  private handleOrganizationTypeChange = (newValue: OrganizationType | null) => {
    this.setState({
      newHOOrg: {
        ...this.state.newHOOrg,
        organizationType: newValue,
        organizationTypeId: newValue !== null ? newValue!.id : 0
      }
    });
  };

  public saveOrganization = async () => {
    try {
      const ref = Referentials.referential.find(
        (item) => item.code === ReferentialCode.OrganizationLevel
      );
      const orgLevelTypes = await this.appReferentialService.Get(ref!.baseUrl);

      const hoType = orgLevelTypes.find((item) => item.code === OrganizationLevelTypeCode.HO);

      const newOrg = this.state.newHOOrg;

      if (hoType !== undefined) {
        newOrg.organizationLevelTypeId = hoType.id;
        newOrg.organizationLevelType = hoType as any as OrganizationLevelType;
      }

      await this.organizationService.AddOrganization(this.state.newHOOrg);

      await this.loadOrganizations();

      this.closeDialog();

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  public renderFilters = () => {
    return (
      <Box m={1} p={1}>
        <Card>
          <CardContent>
            <Tabs
              value={this.state.selectedFilter}
              indicatorColor="primary"
              textColor="secondary"
              variant="standard"
              color="primary"
              onChange={(event: React.ChangeEvent<any>, newValue: number) =>
                this.filterChange(event, newValue)
              }
            >
              <Tab
                label={this.translatorService.Tranlate(
                  'ORGANIZATIONS_FILTER_PARTENERS',
                  'Parteneri'
                )}
              />
            </Tabs>
          </CardContent>
        </Card>
      </Box>
    );
  };

  closeDialog = () => {
    this.setState({ isDialogOpen: false });
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    const options = {
      filter: true,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      // responsive: "stacked",
      customToolbar: () => {
        return (
          <Tooltip title={this.translatorService.Tranlate('Add', 'Adauga')}>
            <IconButton aria-label="add" color="primary" className="m-2" onClick={this.newHOOrg}>
              <AddCircleTwoToneIcon />
            </IconButton>
          </Tooltip>
        );
      }
    };

    return (
      <Fragment>
        {this.renderFilters()}
        <Box m={1} p={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.filterOrganizations(this.state.organizations)}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>

        <Dialog
          onClose={this.closeDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.isDialogOpen}
          fullWidth={true}
          maxWidth={'sm'}
        >
          <DialogTitle id="customized-dialog-title">
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h4">
                  {this.translatorService.Tranlate(
                    'ORGANIZATIONS_NEW_HO_MODAL_TITLE',
                    'Adauga organizatie'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton aria-label="close" onClick={this.closeDialog} size={'small'}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <ValidatorForm onSubmit={this.saveOrganization} instantValidate={true}>
              <TextValidator
                fullWidth
                name="name"
                className="m-2"
                id="name"
                value={this.state.newHOOrg.name || ''}
                onChange={this.handleTextChange}
                label={this.translatorService.Tranlate('EDIT_ORGANIZATION_NAME_LABEL', 'Nume')}
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
              />

              <Autocomplete
                id="organizationType"
                className="m-2"
                options={this.state.organizationTypes}
                value={this.state.newHOOrg.organizationType}
                onChange={(e: any, newValue: OrganizationType | null) =>
                  this.handleOrganizationTypeChange(newValue)
                }
                getOptionLabel={(option: OrganizationType) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="organizationType"
                    value={this.state.newHOOrg.organizationType}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_ORGANIZATION_TYPE_LABEL',
                      'Tip Organizatie'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
              <div className="text-right">
                <Button
                  className="m-2"
                  variant="outlined"
                  color="primary"
                  onClick={this.closeDialog}
                >
                  {this.translatorService.Tranlate('EDIT_ORGANIZATION_CANCEL_BTN', 'Anuleaza')}
                </Button>
                <Button className="m-2" variant="contained" color="primary" type="submit">
                  {this.translatorService.Tranlate('EDIT_ORGANIZATION_SAVE_BTN', 'Salveaza')}
                </Button>
              </div>
            </ValidatorForm>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(MavoHOOrganizations as any));
