import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { ITranslatorService } from '../../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../../context/Contexts';
import { IOrganizationService } from '../../../../services/Interfaces/IOrganizationService';
import { Box, Button, IconButton, Card, CardContent } from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import moment from 'moment';
import { MUITranslations } from '../../../../helpers/MUITableTranslations';
import { RouteComponentProps } from 'react-router';
import { IReferential } from '../../../../interfaces/IReferential';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { IReferentialService } from '../../../../services/Interfaces/IReferentialService';
import Referentials from '../../../../helpers/Referentials.json';
import { ReferentialCode } from '../../../../helpers/Constants';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { WorkflowFormReport, WorkflowPartner } from '../../../../interfaces/Workflow';
import { IWorkflowService } from '../../../../services/Interfaces/IWorkflowService';
import { isNullOrUndefined } from 'util';

interface IWorkflowReportState {
  workflowReports: WorkflowFormReport[];
  isLoading: boolean;
  statusList: IReferential[];
  organizations: WorkflowPartner[];
  reportList: IReferential[];
  selectedStatus: IReferential | null;
  selectedOrganization: WorkflowPartner | null;
  selectedReport: IReferential | null;
  selectedUserRole: IReferential | null;
  userRoles: IReferential[];
}
export interface ExternalOrganizationFormReportProps {
  workflowFormId: number;
}

type IOrganizationFormReportProps = ExternalOrganizationFormReportProps & {
  appState: AppState;
} & ProviderContext &
  RouteComponentProps<{ id: string; workflowId: string }>;

class EditWorkflowFormReport extends React.PureComponent<
  IOrganizationFormReportProps,
  IWorkflowReportState
> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private workflowService!: IWorkflowService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    workflowReports: [],
    isLoading: false,
    statusList: [],
    organizations: [],
    reportList: [],
    selectedStatus: null,
    selectedOrganization: null,
    selectedReport: null,
    selectedUserRole: null,
    userRoles: []
  } as IWorkflowReportState;

  public componentDidMount() {
    const hoId = Number.parseInt(this.props.match.params.id);

    if (Number.isNaN(hoId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadOrganizationFormReports();
      }
    );
  }

  loadOrganizationFormReports = async () => {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);

    if (Number.isNaN(hoId)) {
      return;
    }
    const ref = Referentials.referential.find((item) => item.code === ReferentialCode.CaseStatus);
    const refReport = Referentials.referential.find((item) => item.code === ReferentialCode.Report);
    const refRole = Referentials.referential.find((item) => item.code === ReferentialCode.UserRole);

    const [workflowFormReports, statusList, reportList, workflowPartnerList, userRoles] =
      await Promise.all([
        this.workflowService.GetWorkflowFormReports(this.props.workflowFormId),
        this.appReferentialService.Get(ref!.baseUrl),
        this.appReferentialService.Get(refReport!.baseUrl),
        this.workflowService.GetWorkflowPartners(workflowId),
        this.appReferentialService.Get(refRole!.baseUrl)
      ]);

    const partners = await this.organizationService.GetOrganizationsByIds(
      workflowPartnerList.map((item) => item.partnerId)
    );
    workflowPartnerList.forEach((wpItem) => {
      const partner = partners.find((item) => item.id === wpItem.partnerId);
      wpItem.partner = isNullOrUndefined(partner) ? null : partner;
    });

    workflowFormReports.forEach((wfrItem) => {
      const wc = workflowPartnerList.find((item) => item.id === wfrItem.workflowPartnerId);
      const cs = statusList.find((item) => item.id === wfrItem.caseStatusId);

      wfrItem.workflowPartner = isNullOrUndefined(wc) ? null : wc;
      wfrItem.caseStatus = isNullOrUndefined(cs) ? null : cs;
    });

    this.setState({
      workflowReports: workflowFormReports.sort(function (a, b) {
        return a.order - b.order;
      }),
      statusList: statusList.filter((item) => item.isActive),
      organizations: workflowPartnerList.filter((item) => item.isActive),
      reportList: reportList,
      isLoading: false,
      userRoles: userRoles.filter((item) => item.isActive === true)
    });
  };

  reloadOrganizationForms = async () => {
    const workflowFormReports = await this.workflowService.GetWorkflowFormReports(
      this.props.workflowFormId
    );
    workflowFormReports.forEach((wfrItem) => {
      const wc = this.state.organizations.find((item) => item.id === wfrItem.workflowPartnerId);
      const cs = this.state.statusList.find((item) => item.id === wfrItem.caseStatusId);

      wfrItem.workflowPartner = isNullOrUndefined(wc) ? null : wc;
      wfrItem.caseStatus = isNullOrUndefined(cs) ? null : cs;
    });

    this.setState({
      workflowReports: workflowFormReports.sort(function (a, b) {
        return a.order - b.order;
      }),
      isLoading: false
    });
  };

  deleteOrgFormReport = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const id = tableMeta.rowData[0];

      await this.workflowService.RemoveWorkflowFormReport(id);

      this.setState(
        {
          isLoading: true
        },
        () => {
          this.reloadOrganizationForms();
        }
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  moveUp = async (event: any, tableMeta: MUIDataTableMeta) => {
    const id = tableMeta.rowData[0];
    const cloneArray = [...this.state.workflowReports];
    const itemToMoveUpIndex = cloneArray.findIndex((item) => item.id === id);

    if (itemToMoveUpIndex === -1) {
      return;
    }
    if (cloneArray[itemToMoveUpIndex].order === 1) {
      return;
    }

    const itemToMoveDownIndex = cloneArray.findIndex(
      (item) => item.order === cloneArray[itemToMoveUpIndex].order - 1
    );

    cloneArray[itemToMoveUpIndex] = {
      ...cloneArray[itemToMoveUpIndex],
      order: cloneArray[itemToMoveUpIndex].order - 1
    };
    cloneArray[itemToMoveDownIndex] = {
      ...cloneArray[itemToMoveDownIndex],
      order: cloneArray[itemToMoveDownIndex].order + 1
    };

    try {
      await this.workflowService.UpdateWorkflowFormReport(cloneArray[itemToMoveUpIndex]);
      await this.workflowService.UpdateWorkflowFormReport(cloneArray[itemToMoveDownIndex]);

      this.setState({
        workflowReports: cloneArray.sort(function (a, b) {
          return a.order - b.order;
        })
      });
      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  moveDown = async (event: any, tableMeta: MUIDataTableMeta) => {
    const id = tableMeta.rowData[0];
    const cloneArray = [...this.state.workflowReports];
    const itemToMoveIndex = cloneArray.findIndex((item) => item.id === id);

    if (itemToMoveIndex === -1) {
      return;
    }
    if (cloneArray[itemToMoveIndex].order === cloneArray.length) {
      return;
    }

    const itemToMoveToIndex = cloneArray.findIndex(
      (item) => item.order === cloneArray[itemToMoveIndex].order + 1
    );

    cloneArray[itemToMoveIndex] = {
      ...cloneArray[itemToMoveIndex],
      order: cloneArray[itemToMoveIndex].order + 1
    };
    cloneArray[itemToMoveToIndex] = {
      ...cloneArray[itemToMoveToIndex],
      order: cloneArray[itemToMoveToIndex].order - 1
    };

    try {
      await this.workflowService.UpdateWorkflowFormReport(cloneArray[itemToMoveIndex]);
      await this.workflowService.UpdateWorkflowFormReport(cloneArray[itemToMoveToIndex]);

      this.setState({
        workflowReports: cloneArray.sort(function (a, b) {
          return a.order - b.order;
        })
      });
      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'workflowPartner',
        label: this.translatorService.Tranlate(
          'EDIT_ORGANIZATION_FORM_REPORT_ORGANIZATION_HEADER',
          'Organizatie'
        ),
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value: WorkflowPartner, tableMeta: MUIDataTableMeta) => {
            return value?.partner?.displayName;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'caseStatus',
        label: this.translatorService.Tranlate(
          'EDIT_ORGANIZATION_FORM_REPORT_STATUS_HEADER',
          'Status'
        ),
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            return value.displayName;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'userRole',
        label: this.translatorService.Tranlate('WORKFLOW_USER_ROLE_LABEL', 'Rol'),
        options: {
          sort: false,
          customBodyRender: (value: any) => {
            return value ? value.displayName : '';
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'report',
        label: this.translatorService.Tranlate(
          'EDIT_ORGANIZATION_FORM_REPORT_REPORT_HEADER',
          'Raport'
        ),
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            return value.displayName;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate(
          'ORGANIZATIONS_DATE_MODIFICATION_HEADER',
          'Data Modificare'
        ),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: Date) =>
            moment.utc(value).local().toDate().toLocaleString(this.props.appState.language)
        } as MUIDataTableColumnOptions
      },
      {
        name: 'order',
        sortDirection: 'asc',
        label: this.translatorService.Tranlate('EDIT_ORGANIZATION_FORM_REPORT_ORDER_HEADER', 'Ord'),
        options: { filter: false, sort: false }
      },
      {
        name: '',
        options: {
          filter: false,
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          sortOrder: {
            name: 'order',
            direction: 'asc'
          },
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <Fragment>
                <IconButton color="inherit" size="small" onClick={(e) => this.moveUp(e, tableMeta)}>
                  <KeyboardArrowUpIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={(e) => this.moveDown(e, tableMeta)}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
              </Fragment>
            );
          }
        }
      },
      {
        name: '',
        options: {
          filter: false,
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <IconButton
                aria-label="delete"
                color="inherit"
                className="text-danger"
                size="small"
                onClick={(e) => this.deleteOrgFormReport(e, tableMeta)}
              >
                <DeleteOutlineTwoToneIcon />
              </IconButton>
            );
          }
        }
      }
    ];

    return columns;
  };

  addOrgFormReport = async () => {
    try {
      if (
        this.state.workflowReports.findIndex(
          (item) =>
            item.workflowPartnerId === this.state.selectedOrganization!.id &&
            ((this.state.selectedUserRole !== null &&
              item.userRoleId === this.state.selectedUserRole!.id) ||
              (this.state.selectedUserRole === null && item.userRoleId !== null) ||
              (this.state.selectedUserRole !== null && item.userRoleId === null) ||
              (this.state.selectedUserRole === null && item.userRoleId === null)) &&
            item.reportId === this.state.selectedReport!.id &&
            item.caseStatusId === this.state.selectedStatus!.id
        ) !== -1
      ) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'ERROR_MSG_WORKFLOW_REPORT_EXISTS',
            'Raportul exista deja!'
          ),
          { variant: 'error' }
        );
        return;
      }
      await this.workflowService.AddWorkflowFormReport({
        id: 0,
        order: this.state.workflowReports.length + 1,
        workflowPartnerId: this.state.selectedOrganization!.id,
        reportId: this.state.selectedReport!.id,
        caseStatusId: this.state.selectedStatus!.id,
        workflowSectionStepFormId: this.props.workflowFormId,
        userRoleId: this.state.selectedUserRole ? this.state.selectedUserRole.id : null
      } as WorkflowFormReport);

      this.setState(
        {
          isLoading: true,
          selectedReport: null
        },
        () => {
          this.loadOrganizationFormReports();
        }
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleAutocompleteStatusChange = (newValue: any | null) => {
    this.setState({
      selectedStatus: newValue
    });
  };

  handleUserRoleChange = (newValue: any | null) => {
    this.setState({ selectedUserRole: newValue });
  };
  handleAutocompleteOrganizationChange = (newValue: WorkflowPartner | null) => {
    this.setState({ selectedOrganization: newValue });
  };

  handleAutocompleteReportChange = async (newValue: any | null) => {
    this.setState({ selectedReport: newValue });
  };

  public renderAddForm() {
    return (
      <Box mt={1} pt={1}>
        <Card elevation={1}>
          <CardContent>
            <ValidatorForm onSubmit={this.addOrgFormReport}>
              <Autocomplete
                id="organizationList"
                className="m-2"
                options={this.state.organizations.sort(function (a, b) {
                  return a.partner!.displayName.localeCompare(b.partner!.displayName);
                })}
                value={this.state.selectedOrganization}
                onChange={(e: any, newValue: WorkflowPartner | null) =>
                  this.handleAutocompleteOrganizationChange(newValue)
                }
                getOptionLabel={(option: WorkflowPartner) => option.partner!.displayName || ''}
                filterOptions={createFilterOptions({
                  matchFrom: 'any',
                  stringify: (option: WorkflowPartner) => option.partner!.displayName
                })}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="partnerList"
                    value={this.state.selectedOrganization}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_FORM_REPORT_ORGANIZATION_LABEL',
                      'Organizatie'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <Autocomplete
                id="statusList"
                className="m-2"
                options={this.state.statusList.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedStatus}
                onChange={(e: any, newValue: any | null) =>
                  this.handleAutocompleteStatusChange(newValue)
                }
                getOptionLabel={(option: any) => option.displayName}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="statusList"
                    value={this.state.selectedStatus}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_FORM_REPORT_STATUS_LABEL',
                      'Status'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
              <Autocomplete
                id="userRole"
                className="m-2"
                options={this.state.userRoles.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedUserRole}
                onChange={(e: any, newValue: any | null) => this.handleUserRoleChange(newValue)}
                getOptionLabel={(option: any) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="userRole"
                    value={this.state.selectedUserRole}
                    label={this.translatorService.Tranlate('WORKFLOW_USER_ROLE_LABEL', 'Rol')}
                    fullWidth
                  />
                )}
              />

              <Autocomplete
                id="reportList"
                className="m-2"
                options={this.state.reportList.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedReport}
                onChange={(e: any, newValue: any | null) =>
                  this.handleAutocompleteReportChange(newValue)
                }
                getOptionLabel={(option: any) => option.displayName}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="reportList"
                    value={this.state.selectedReport}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_FORM_REPORT_REPORT_LABEL',
                      'Report'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <div className="text-right">
                <Button className="m-2" variant="contained" color="primary" type="submit">
                  {this.translatorService.Tranlate('ADMIN_EDIT_USER_ROLES_BTN_ADD', 'ADAUGA')}
                </Button>
              </div>
            </ValidatorForm>
          </CardContent>
        </Card>
      </Box>
    );
  }

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    this.workflowService = (this.context as AppContext).workflowService;

    const options = {
      filter: true,
      search: true,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      elevation: 1
    };

    return (
      <Fragment>
        {this.renderAddForm()}
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.workflowReports}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalOrganizationFormReportProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({ appState: state.app }),
  null,
  mergeProps
)(withSnackbar(EditWorkflowFormReport as any));
