import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import { isNullOrUndefined } from 'util';
import { CaseEventHistory } from '../../interfaces/Case';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import { WorkflowCostCenter, WorkflowSectionStepForm } from '../../interfaces/Workflow';
import { FormHelpers } from '../../helpers/forms/FormHelpers';
import { AppUser } from '../../interfaces/AppUser';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import { Button, Grid } from '@material-ui/core';
import _ from 'lodash';
import { IWorkflowService } from '../../services/Interfaces/IWorkflowService';
import { ReferentialCode } from '../../helpers/Constants';
import Referentials from '../../helpers/Referentials.json';
import { IReferential } from '../../interfaces/IReferential';
import { Autocomplete } from '@material-ui/lab';

interface ICaseDetailsCaseTabEventBillingDetailsFormState {
  formData: CaseEventHistory;
  isLoading: boolean;
  workflowCostCenters: WorkflowCostCenter[];
  hasRights: boolean;
  executing: boolean;
  organizationBranches: OrganizationBranch[];
  selectedOrganizationBranch: OrganizationBranch | null;
  organizationDeliveryBranches: OrganizationBranch[];
  selectedDeliveryOrganizationBranch: OrganizationBranch | null;
  interventionTypesBranches: OrganizationBranch[];
  selectedInterventionTypeBranch: OrganizationBranch | null;
}

interface OrganizationBranch {
  id: number;
  organizationId: number;
  organizationName: string;
  organizationAddress: string;
  children: OrganizationBranch[];
  parentId: number | null;
  path: string | null;
  label: string;
  checked: boolean | null;
}
export interface ExternalCaseDetailsCaseTabEventBillingDetailsFormProps {
  workflowForm: WorkflowSectionStepForm;
}

type ICaseDetailsCaseTabEventBillingDetailsFormProps = {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
} & typeof CaseSettingsActionCreators &
  ExternalCaseDetailsCaseTabEventBillingDetailsFormProps &
  ProviderContext &
  RouteComponentProps<{ id: string }>;

class CaseDetailsCaseTabEventBillingDetailsForm extends React.PureComponent<
  ICaseDetailsCaseTabEventBillingDetailsFormProps,
  ICaseDetailsCaseTabEventBillingDetailsFormState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private appReferentialService!: IReferentialService;
  private organizationService!: IOrganizationService;
  private workflowService!: IWorkflowService;

  static contextType = ApplicationContext;
  state = {
    formData: {} as CaseEventHistory,
    isLoading: true,
    workflowCostCenters: [],
    hasRights: false,
    executing: false,
    organizationDeliveryBranches: [],
    selectedDeliveryOrganizationBranch: null,
    organizationBranches: [],
    selectedOrganizationBranch: null,
    interventionTypesBranches: [],
    selectedInterventionTypeBranch: null
  } as ICaseDetailsCaseTabEventBillingDetailsFormState;

  public componentDidMount() {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (isNullOrUndefined(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadData();
      }
    );
  }

  loadData = async () => {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);

    if (isNullOrUndefined(caseSettings) || Number.isNaN(caseId)) {
      return;
    }
    
    const refInterventionType = Referentials.referential.find(
      (item) => item.code === ReferentialCode.InterventionType
    );
    let caseEvent = this.props.caseSettingsState.case!.caseEvent;
    const [newCaseEvent, workflowCostCenters, interventionTypes] = await Promise.all([
      this.caseService.GetCurrentEvent(caseId),
      this.workflowService.GetUserWorkflowCostCenters(
        this.props.caseSettingsState.caseSettings?.workflow.id || 0
      ),
      this.appReferentialService.Get(refInterventionType!.baseUrl)
    ]);

    if (!isNullOrUndefined(newCaseEvent)) {
      caseEvent.organizationId = newCaseEvent.organizationId;
      caseEvent.deliveryOrganizationId = newCaseEvent.deliveryOrganizationId;
      caseEvent.interventionTypeId = newCaseEvent.interventionTypeId;
      this.props.SetCaseEvent(caseEvent);
    }

    //Cost Centers
    const organzationListBranches = [] as OrganizationBranch[];
    for (const x of workflowCostCenters) {
      organzationListBranches.push({
        id: x!.costCenter!.id,
        organizationId: x!.costCenter!.id,
        organizationName: x!.costCenter!.displayName,
        organizationAddress: '',
        children: [],
        parentId: null,
        path: null,
        label: x!.costCenter!.displayName,
        checked: false
      });
    }

    let selectedOrganization = null;
    if (!isNullOrUndefined(caseEvent)) {
      selectedOrganization =
        organzationListBranches.find(
            (x) => x.organizationId === caseEvent.organizationId
          ) || null;
      if (selectedOrganization != null) {
        selectedOrganization.checked = true;
      }
    }

    //Intervention Types
    const interventionTypeListBranches = [] as OrganizationBranch[];
    for (const x of interventionTypes) {
      interventionTypeListBranches.push({
        id: x!.id,
        organizationId: x!.id,
        organizationName: x!.displayName,
        organizationAddress: '',
        children: [],
        parentId: null,
        path: null,
        label: x!.displayName,
        checked: false
      });
    }

    let selectedInterventionType = null;
    if (!isNullOrUndefined(caseEvent)) {
      selectedInterventionType =
        interventionTypeListBranches.find(
            (x) => x.id === caseEvent.interventionTypeId
          ) || null;
      if (selectedInterventionType != null) {
        selectedInterventionType.checked = true;
      }
    }

    let workflowPartners = [] as OrganizationBranch[];
    let selectedDeliveryOrganization = null;
    if (
      this.props.caseSettingsState.caseSettings?.workflow.workflowPartners &&
      this.props.caseSettingsState.caseSettings?.workflow.workflowPartners.length > 0
    ) {
      const partnerOrganizations =
        this.props.caseSettingsState.caseSettings?.workflow.workflowPartners.map((x) => x.partner);
      for (const x of partnerOrganizations) {
        workflowPartners.push({
          id: x!.id,
          organizationId: x!.id,
          organizationName: x!.name,
          organizationAddress: x!.address,
          children: [],
          parentId: x!.parentId,
          path: null,
          label: x?.name + (x?.address ? ' ' + x?.address : ''),
          checked: false
        });
      }
    }

    const parentIds = workflowPartners.filter((x) => !x.parentId).map((x) => x.id);

    const organizationHierarchy = await this.organizationService.GetOrganizationDescendents(
      parentIds
    );

    const organizationDeliveryBranches =
      organizationHierarchy.map((x) => {
        return {
          id: x.id,
          organizationId: x.id,
          organizationName: x.name,
          organizationAddress: x.address,
          children: [],
          parentId: x.parentId,
          path: null,
          label: x.name + (x.address ? ' ' + x.address : ''),
          checked: false
        } as OrganizationBranch;
      }) || [];

    organizationDeliveryBranches.forEach((x) => {
        workflowPartners.push(x);
    });

    const arrMap = workflowPartners.reduce((acc: any, el: any, i) => {
      acc[el.id] = i;
      return acc;
    }, {});

    const roots = [] as OrganizationBranch[];

    // Push each element to parent's children array
    workflowPartners.forEach((el) => {
      if (el.parentId === null || arrMap[el.parentId] == null) {
        roots.push(el);
      } else {
        workflowPartners[arrMap[el.parentId]].children.push(el);
      }
    });
    workflowPartners = roots;
    this.assignObjectPaths(workflowPartners, '');
    
    if (!isNullOrUndefined(caseEvent)) {
      selectedDeliveryOrganization =
      roots.find(
            (x) => x.organizationId === caseEvent.deliveryOrganizationId
          ) || null;
      if (selectedDeliveryOrganization != null) {
        selectedDeliveryOrganization.checked = true;
      }

      if (selectedDeliveryOrganization == null) {
        selectedDeliveryOrganization =
        organizationDeliveryBranches.find(
            (x) => x.organizationId === caseEvent.deliveryOrganizationId
          ) || null;
        if (selectedDeliveryOrganization != null) {
          selectedDeliveryOrganization.checked = true;
        }
      }
    }

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      this.props.caseSettingsState.case!.caseStatus.caseStatusId
    );

    if (isNullOrUndefined(this.props.caseSettingsState.case!.caseEvent)) {
      caseEvent = {
        id: 0,
        caseId: caseId
      } as CaseEventHistory
    }

    this.setState({
      formData: caseEvent,
      isLoading: false,
      workflowCostCenters: workflowCostCenters,
      hasRights: hasRights,
      organizationBranches: organzationListBranches,
      selectedOrganizationBranch: selectedOrganization || null,
      organizationDeliveryBranches: workflowPartners || [],
      selectedDeliveryOrganizationBranch: selectedDeliveryOrganization || null,
      interventionTypesBranches: interventionTypeListBranches,
      selectedInterventionTypeBranch: selectedInterventionType || null
    });
  };

  assignObjectPaths = (obj: any, stack: any) => {
    Object.keys(obj).forEach((k) => {
      const node = obj[k];
      if (node && typeof node === 'object' && !Array.isArray(node)) {
        node.path = stack ? `${stack}.${k}` : k;
        this.assignObjectPaths(node, node.path);
      }
    });
  };

  findRecursive = (
    arr: OrganizationBranch[],
    organizationId: number
  ): OrganizationBranch | null => {
    let result: OrganizationBranch | null = null;
    for (const entry in arr) {
      if (arr[entry].organizationId == organizationId) {
        result = arr[entry];
        break;
      }
      if (arr[entry].children?.length) {
        const partialResult = this.findRecursive(arr[entry].children, organizationId);
        if (partialResult != null) {
          result = partialResult;
          break;
        }
      }
    }
    return result;
  };

  
  onDropDownChange = (currentNode: any, selectedNodes: any) => {
    // react-dropdown-tree-select doesn't have an intrinsic mechanism for keeping track of selected items, only provides what is selected
    // So we have to manually check/uncheck the leaves from this tree used by this component
    let newSelectionOrganizationBranch: OrganizationBranch | null = null;
    if (this.state.selectedOrganizationBranch) {
      const oldSelectionOrganizationBranch = this.findRecursive(
        this.state.organizationBranches,
        this.state.selectedOrganizationBranch.organizationId
      );
      if (oldSelectionOrganizationBranch) {
        oldSelectionOrganizationBranch.checked = false;
      }
    }

    if (selectedNodes.length == 1) {
      const newPartialSelectionOrganizationBranch =
        this.findRecursive(
          this.state.organizationBranches,
          (selectedNodes[0] as OrganizationBranch)?.organizationId
        ) || null;

      if (newPartialSelectionOrganizationBranch) {
        newPartialSelectionOrganizationBranch.checked = true;
        newSelectionOrganizationBranch = newPartialSelectionOrganizationBranch;
      }
    }

    this.setState({
      ...this.state,
      selectedOrganizationBranch: newSelectionOrganizationBranch
    });
  };


  onDeliveryDropDownChange = (currentNode: any, selectedNodes: any) => {
    // react-dropdown-tree-select doesn't have an intrinsic mechanism for keeping track of selected items, only provides what is selected
    // So we have to manually check/uncheck the leaves from this tree used by this component
    let newSelectionOrganizationBranch: OrganizationBranch | null = null;
    if (this.state.selectedDeliveryOrganizationBranch) {
      const oldSelectionOrganizationBranch = this.findRecursive(
        this.state.organizationDeliveryBranches,
        this.state.selectedDeliveryOrganizationBranch.organizationId
      );
      if (oldSelectionOrganizationBranch) {
        oldSelectionOrganizationBranch.checked = false;
      }
    }

    if (selectedNodes.length == 1) {
      const newPartialSelectionOrganizationBranch =
        this.findRecursive(
          this.state.organizationDeliveryBranches,
          (selectedNodes[0] as OrganizationBranch)?.organizationId
        ) || null;

      if (newPartialSelectionOrganizationBranch) {
        newPartialSelectionOrganizationBranch.checked = true;
        newSelectionOrganizationBranch = newPartialSelectionOrganizationBranch;
      }
    }

    this.setState({
      ...this.state,
      selectedDeliveryOrganizationBranch: newSelectionOrganizationBranch
    });
  };

  onInteventionTypeDropDownChange = (currentNode: any, selectedNodes: any) => {
    // react-dropdown-tree-select doesn't have an intrinsic mechanism for keeping track of selected items, only provides what is selected
    // So we have to manually check/uncheck the leaves from this tree used by this component
    let newInterventionTypeBranch: OrganizationBranch | null = null;
    if (this.state.selectedDeliveryOrganizationBranch) {
      const oldInterventionTypeBranch = this.findRecursive(
        this.state.interventionTypesBranches,
        this.state.selectedDeliveryOrganizationBranch.organizationId
      );
      if (oldInterventionTypeBranch) {
        oldInterventionTypeBranch.checked = false;
      }
    }

    if (selectedNodes.length == 1) {
      const newPartialSelectionInterventionTypeBranch =
        this.findRecursive(
          this.state.interventionTypesBranches,
          (selectedNodes[0] as OrganizationBranch)?.organizationId
        ) || null;

      if (newPartialSelectionInterventionTypeBranch) {
        newPartialSelectionInterventionTypeBranch.checked = true;
        newInterventionTypeBranch = newPartialSelectionInterventionTypeBranch;
      }
    }

    this.setState({
      ...this.state,
      selectedInterventionTypeBranch: newInterventionTypeBranch
    });
  };

  onSubmit = async () => {
    try {
      this.setState({ executing: true });
      if (isNullOrUndefined(this.state.selectedOrganizationBranch)) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('CASE_EVENT_COST_CENTER_MANDATORY', 'Centru de cost este obligatoriu!'), 
        {
          variant: 'error'
        });
        this.setState({ executing: false });
        return;
      }

      if (isNullOrUndefined(this.state.selectedDeliveryOrganizationBranch)) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('CASE_EVENT_LOCATION_MANDATORY', 'Adresa de livrare este obligatorie!'), 
        {
          variant: 'error'
        });
        this.setState({ executing: false });
        return;
      }

      // if (isNullOrUndefined(this.state.selectedInterventionTypeBranch)) {
      //   this.props.enqueueSnackbar(this.translatorService.Tranlate('CASE_EVENT_INTERVENTION_TYPE_MANDATORY', 'Tip interventie este obligatoriu!'), 
      //   {
      //     variant: 'error'
      //   });
      //   this.setState({ executing: false });
      //   return;
      // }

      let data = this.state.formData as CaseEventHistory;
      data.caseId = this.props.caseSettingsState.case!.id;
      data.organizationId = this.state.selectedOrganizationBranch!.id;
      data.deliveryOrganizationId = this.state.selectedDeliveryOrganizationBranch!.id;
      data.insuredPersonType = null;
      // data.interventionTypeId = this.state.selectedInterventionTypeBranch!.id;
      if (isNullOrUndefined(data.id)) {
        data.id = 0;
      }

      if (data.id === 0) {
        data = await this.caseService.AddCaseEvent(data);
      } else {
        await this.caseService.UpdateCaseEvent(data);
      }
      if (this.props.workflowForm.form.hasActions) {
        await this.submitForm(data.caseId, this.props.workflowForm.id, null);
      }

      this.props.SetCaseEvent(data);

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (err) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    } finally {
      this.setState({ executing: false });
    }
  };

  submitForm = async (caseId: number, caseSectionStepFormId: number, appUser: AppUser | null) => {
    let newStatus = await this.caseService.SubmitForm(caseId, caseSectionStepFormId, appUser);

    if (newStatus === null) {
      newStatus = this.props.caseSettingsState.case!.caseStatus;
    }
    this.props.SetCaseStatus(newStatus);
    if (appUser !== null) {
      this.props.AddPartner(appUser!.hoId === null ? appUser!.organizationId : appUser!.hoId);
    }

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      newStatus.caseStatusId
    );
    this.setState({ hasRights: hasRights });
  };

  renderFormData = (form: WorkflowSectionStepForm) => {
    return (
      <div className="px-5 pb-3">
          <ValidatorForm
            onSubmit={(e) => {
              this.onSubmit();
            }}
            >  
          <Grid container>
            <Grid item xs={12} className="mt-4">
              <div>
                {this.translatorService.Tranlate(
                      'CASE_EVENT_COST_CENTER',
                      'Centru de cost'
                    )} *
              </div>
              <div className="mt-1">
                <DropdownTreeSelect
                  className="billing-tree-select"
                  data={this.state.organizationBranches}
                  onChange={this.onDropDownChange}
                  mode="radioSelect"
                  texts={{
                    placeholder: this.translatorService.Tranlate(
                      'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_CHOOSE_TEXT',
                      'Selecteaza'
                    )
                  }}
                  disabled={!this.state.hasRights}
                ></DropdownTreeSelect>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                {this.translatorService.Tranlate(
                      'CASE_EVENT_LOCATION_LABEL',
                      'Adresa de livrare'
                    )} *
              </div>
              <div className="mt-1">
                <DropdownTreeSelect
                  className="billing-tree-select"
                  data={this.state.organizationDeliveryBranches}
                  onChange={this.onDeliveryDropDownChange}
                  mode="radioSelect"
                  texts={{
                    placeholder: this.translatorService.Tranlate(
                      'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_CHOOSE_TEXT',
                      'Selecteaza'
                    )
                  }}
                  disabled={!this.state.hasRights}
                ></DropdownTreeSelect>
              </div>
            </Grid>
            {/* <Grid item xs={12}>
              <div>
                {this.translatorService.Tranlate('CASE_EVENT_INTERVENTION_TYPE_LABEL','Tip Interventie')} *
              </div>
              <div className="mt-1">
                <DropdownTreeSelect
                  className="billing-tree-select"
                  data={this.state.interventionTypesBranches}
                  onChange={this.onInteventionTypeDropDownChange}
                  mode="radioSelect"
                  texts={{
                    placeholder: this.translatorService.Tranlate(
                      'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_CHOOSE_TEXT',
                      'Selecteaza'
                    )
                  }}
                  disabled={!this.state.hasRights}
                ></DropdownTreeSelect>
              </div>
            </Grid> */}
          </Grid>   
          <div className="text-center">
            <Button
              className="m-2"
              variant="contained"
              color="primary"
              type="submit"
              disabled={!this.state.hasRights || this.state.executing}
            >
              {this.translatorService.Tranlate('SAVE', 'Salveaza')}
            </Button>
          </div>
        
        </ValidatorForm>
      </div>  
     
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.workflowService = (this.context as AppContext).workflowService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? <div>{this.renderFormData(this.props.workflowForm)}</div> : null}
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseDetailsCaseTabEventBillingDetailsFormProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  CaseSettingsActionCreators,
  mergeProps
)(withSnackbar(CaseDetailsCaseTabEventBillingDetailsForm as any));
