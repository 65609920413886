import React, { Fragment, useContext, useEffect  } from 'react';
import { ApplicationContext } from '../../context/Contexts';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Hidden, IconButton, Box, Tabs, Tab, Popover, makeStyles } from '@material-ui/core';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import PollIcon from '@material-ui/icons/Poll';
import CaseNewInNotification from '../../components/cases/CaseNewInNotification';
import CaseMessageNotification from '../../components/cases/CaseMessageNotification';
import CaseTask from '../../components/cases/CaseTask';
import TabPanel from '../../components/_shared/Tabs';
import { connect } from 'react-redux';
import helpLogo from '../../assets/images/info.svg';
import MenuBookRoundedIcon from '@material-ui/icons/MenuBookRounded';
import AppointmentNewInNotification from '../../components/cases/AppointmentNewInNotification';
import { CountryCodeEnum } from '../../helpers/Constants';
import { withRouter } from 'react-router';
import { AppActionCreators } from '../../store';
import { useHistory } from 'react-router-dom'; // Import useHistory hook
import { TranslatorService } from '../../services/TranslatorService';
import { useAppDispatch, useAppSelector } from '../../store/configureStore';
import {
  AGENDA_SET_SELECTED_RANGE
} from '../../store/actions/ActionTypes';
import HelpRounded from '@material-ui/icons/HelpRounded';


const HeaderDots = (props) => {
  const context = useContext(ApplicationContext);
  const translatorService = new TranslatorService();

  const { appState, loadUserNotifications  } = props;
  const history = useHistory(); // Initialize useHistory
  const dispatch = useAppDispatch();

  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);

  useEffect(() => {
    console.log('HeaderDots mounted');
    // Call loadUserNotifications on component mount
    loadUserNotifications();

    // Listen for navigation changes
    const unlisten = history.listen(() => {
      console.log('Navigation occurred');
      dispatch({
        type: AGENDA_SET_SELECTED_RANGE,
        payload: null
      });
      loadUserNotifications();
    });

    // Cleanup
    return () => {
      unlisten();
    };
  }, [loadUserNotifications, history]); // Include loadUserNotifications and history in the dependency array


  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleClick2 = (event) => {
    setValue(0);
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };
  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);

  const [value, setValue] = React.useState(0);
  const [countValue, setNewCasesCount] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const useStyle = makeStyles({
    indicator: {
      width: '100% !important'
    }
  });
  const useStyle2 = makeStyles({
    indicator: {
      width: '33.33% !important'
    }
  });

  const useStyle3 = makeStyles({
    indicator: {
      width: '50% !important'
    }
  });

  const classes = useStyle();
  const classes2 = useStyle2();
  const classes3 = useStyle3();

  const checkSelectedTab = () => {
    let tabContainer = document.getElementById('tabs-container');
    let secondTabSelected = tabContainer
      .getElementsByTagName('button')[1]
      .getAttribute('aria-selected');
    let thirdTabSelected = tabContainer
      .getElementsByTagName('button')[2]
      .getAttribute('aria-selected');

    if (secondTabSelected === 'true') {
      document.getElementById('tabs-container').getElementsByTagName('span')[6].style.left = '33.33%';
    } else if (thirdTabSelected === 'true') {
      document.getElementById('tabs-container').getElementsByTagName('span')[6].style.left = '66.66%'; 
    }
  };

  const checkSelectedTab2 = () => {
    let tabContainer = document.getElementById('tabs-container');
    let casesTabSelected = tabContainer
      .getElementsByTagName('button')[1]
      .getAttribute('aria-selected');

    if (casesTabSelected === 'true') {
      document.getElementById('tabs-container').getElementsByTagName('span')[4].style.left = '50%';
    }
  };

  return (
    <Fragment>
      <div className="d-flex align-items-center popover-header-wrapper">
      {props.appState.appUser?.organization?.country?.code?.toUpperCase() === CountryCodeEnum.CZ.toString() ? (
        <Hidden smDown>
          <Box component="span" pr="2">
            <IconButton onClick={handleClick3} color="inherit" className="btn-inverse mx-1 d-50">
              <div className="badge badge-pill badge-warning badge-header">
                {props.appState.comments.length + props.appState.newInCases.filter(item => !item.caseStatus.isViewed).length + props.appState.newInAppointments.filter(item => !item.appointmentStatusHistory.isViewed).length}
              </div>
              <NotificationsActiveIcon style={{ fontSize: 30 }} />
            </IconButton>
            <Popover
              onEntering={checkSelectedTab2}
              open={open3}
              anchorEl={anchorEl3}
              onClose={handleClose3}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              classes={{
                paper: 'app-header-dots'
              }}
            >
              <div className="popover-custom-xl overflow-hidden">
                <div className="mx-2">
                  <Tabs
                    classes={{ indicator: classes3.indicator }}
                    id="tabs-container"
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    onChange={handleChange}
                  >
                    <Tab
                      className="py-3"
                      label={translatorService.Tranlate('APPOINTMENTS_NEW_ENTRIES', 'Programari')}
                    />
                    <Tab
                      className="py-3"
                      label={translatorService.Tranlate('CASE_NEW_ENTRIES', 'Dosare')}
                    />
                  </Tabs>
                </div>
                <div className="height-280">
                  <PerfectScrollbar>
                    <TabPanel value={value} index={0} className="p-0">
                      <AppointmentNewInNotification onClick={handleClose1}></AppointmentNewInNotification>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <CaseNewInNotification onClick={handleClose1}></CaseNewInNotification>
                    </TabPanel>
                  </PerfectScrollbar>
                  {/* <CaseNotifications selectedTab={value}></CaseNotifications> */}
                </div>
              </div>
            </Popover>
          </Box>
        </Hidden>
        ) : (
        <Hidden smDown>
          <Box component="span" pr="2">
            <IconButton onClick={handleClick1} color="inherit" className="btn-inverse mx-1 d-50">
              <div className="badge badge-pill badge-warning badge-header">
                {props.appState.comments.length + props.appState.newInCases.filter(item => !item.caseStatus.isViewed).length + props.appState.newInAppointments.filter(item => !item.appointmentStatusHistory.isViewed).length}
              </div>
              <NotificationsActiveIcon style={{ fontSize: 30 }} />
            </IconButton>
            <Popover
              onEntering={checkSelectedTab}
              open={open1}
              anchorEl={anchorEl1}
              onClose={handleClose1}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              classes={{
                paper: 'app-header-dots'
              }}
            >
              <div className="popover-custom-xl overflow-hidden">
                <div className="mx-2">
                  <Tabs
                    classes={{ indicator: classes2.indicator }}
                    id="tabs-container"
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    onChange={handleChange}
                  >
                   <Tab
                      className="py-3"
                      label={translatorService.Tranlate('CASE_NEW_ENTRIES', 'Dosare')}
                    />
                    <Tab
                      className="py-3"
                      label={translatorService.Tranlate('APPOINTMENTS_NEW_ENTRIES', 'Programari')}
                    />
                    <Tab
                      className="py-3"
                      label={translatorService.Tranlate('CASE_MESSAGES', 'Mesaje')}
                    />
                  </Tabs>
                </div>
                <div className="height-280">
                  <PerfectScrollbar>
                    <TabPanel value={value} index={0} className="p-0">
                      <CaseNewInNotification onClick={handleClose1}></CaseNewInNotification>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <AppointmentNewInNotification onClick={handleClose1}></AppointmentNewInNotification>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <CaseMessageNotification onClick={handleClose1}></CaseMessageNotification>
                    </TabPanel>
                  </PerfectScrollbar>
                  {/* <CaseNotifications selectedTab={value}></CaseNotifications> */}
                </div>
              </div>
            </Popover>
          </Box>
        </Hidden>
        )}
        <Box component="span" pr="2">
          {props.appState.appUser?.organization?.country?.code?.toUpperCase() === CountryCodeEnum.CZ.toString() ? null : (
            <IconButton onClick={handleClick2} color="inherit" className="btn-inverse mx-1 d-50">
              <div className="badge badge-pill badge-success badge-header">
                {props.appState.tasks.length}
              </div>
              <PollIcon style={{ fontSize: 30 }} />
            </IconButton>
            )
          }
          <IconButton onClick={() =>  {
              props.history.push('/user-manual');
            }} 
            color="inherit"
            className="btn-inverse mx-1 d-50">
            <MenuBookRoundedIcon style={{ fontSize: 30 }} />
          </IconButton>
          {/* <IconButton onClick={() =>  {
              props.history.push('/general-notification');
            }} 
            color="inherit"
            className="btn-inverse mx-1 d-50">
              <HelpRounded style={{ fontSize: 30 }} />
          </IconButton> */}
          <Popover
            open={open2}
            anchorEl={anchorEl2}
            onClose={handleClose2}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            <div className="popover-custom-xl overflow-hidden">
              <div className="mx-2">
                <Tabs
                  classes={{ indicator: classes.indicator }}
                  value={value}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  onChange={handleChange}
                >
                  <Tab
                    className="py-3"
                    label={translatorService.Tranlate('CASE_TASKS', 'Lista sarcini')}
                  />
                </Tabs>
              </div>
              <div className="height-280">
                <PerfectScrollbar>
                  <TabPanel value={value} index={0} className="p-0">
                    <CaseTask onClick={handleClose2}></CaseTask>
                  </TabPanel>
                </PerfectScrollbar>
              </div>
            </div>
          </Popover>
        </Box>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.app.user,
  appState: state.app
});

const mapDispatchToProps = (dispatch) => ({
  loadUserNotifications: () => dispatch(AppActionCreators.LoadUserNotifications())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderDots));
